import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

function EmployeeNew() {
  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState('');
  const [model, setModel] = useState('');
  const [company, setCompany] = useState('');
  const [user, setUser] = useState('');
  const [start, setStart] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [end, setEnd] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const handleSave = async () => {
    try {
      await axios.post('http://221.143.168.230:3500/vehicle/new', {
        vehicle: vehicle,
        model: model,
        company: company,
        user: user,
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD'),
      });
    } catch (err) {
      console.log(err);
    }
    navigate('/vehicle');
  };

  return (
    <div className="container">
      <div className="h5 font-weight-bold mt-4">차량 정보</div>
      <table className="table table-borderless mt-4">
        <tbody>
          <tr>
            <td style={{ width: '100px' }}>차량번호</td>
            <td>
              <input
                type="text"
                id="vehicle"
                className="form-control"
                style={{ width: '200px', textAlign: 'center' }}
                value={vehicle}
                autoComplete="off"
                placeholder="등록차량 번호"
                onChange={(e) => setVehicle(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>차량모델</td>
            <td>
              <input
                type="text"
                id="model"
                className="form-control"
                style={{ width: '400px' }}
                value={model}
                autoComplete="off"
                placeholder="차량 모델"
                onChange={(e) => setModel(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>보험회사</td>
            <td>
              <input
                type="text"
                id="company"
                className="form-control"
                style={{ width: '200px' }}
                value={company}
                autoComplete="off"
                placeholder="차량 보험회사"
                onChange={(e) => setCompany(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>사용자</td>
            <td>
              <input
                type="text"
                id="user"
                className="form-control"
                style={{ width: '150px' }}
                value={user}
                autoComplete="off"
                placeholder="차량 주 사용자"
                onChange={(e) => setUser(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>보험시작일</td>
            <td>
              <input
                type="date"
                id="start"
                className="form-control"
                style={{ width: '200px' }}
                value={start}
                onChange={(e) => setStart(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>보험만료일</td>
            <td>
              <input
                type="date"
                id="end"
                className="form-control"
                style={{ width: '200px' }}
                value={end}
                onChange={(e) => setEnd(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  width: '100px',
                  marginLeft: '75px',
                  marginTop: '50px',
                  height: '35px',
                }}
                onClick={handleSave}
              >
                저장
              </button>
              <Link
                to="/vehicle"
                className="btn btn-success"
                style={{
                  width: '100px',
                  marginLeft: '400px',
                  marginTop: '50px',
                  height: '35px',
                }}
              >
                목록
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EmployeeNew;
