import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../Users/useAuth';
import { BsPeopleFill } from 'react-icons/bs';

function EmployeeNew() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [position, setPosition] = useState('');
  const [password, setPassword] = useState('0000');
  const [newDate, setNewDate] = useState('');
  const [roles, setRoles] = useState('["1964"]');

  const handleSave = async () => {
    try {
      await axios.post('http://221.143.168.230:3500/employee', {
        username: username,
        position: position,
        password: password,
        newdate: newDate,
        roles: roles,
      });
    } catch (err) {
      console.log(err);
    }
    navigate('/employee');
  };

  const handleSelectRole = (e) => {
    setRoles(e.target.value);
  };

  return (
    <div className="container">
      <div className="h5 font-weight-bold mt-4">
        <BsPeopleFill /> 직원 등록
      </div>
      <hr className="mt-4" />
      <table className="table table-borderless mt-4">
        <tbody>
          <tr>
            <td style={{ width: '100px' }}>이름 :</td>
            <td>
              <input
                type="text"
                id="username"
                autoComplete="off"
                className="form-control"
                style={{ width: '120px', textAlign: 'center' }}
                value={username}
                placeholder="이름 입력"
                onChange={(e) => setUsername(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>직위</td>
            <td>
              <input
                type="text"
                id="position"
                className="form-control"
                style={{ width: '500px' }}
                placeholder="직위를 입력해 주세요"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>비번</td>
            <td>
              <input
                type="text"
                id="password"
                className="form-control"
                style={{ width: '500px' }}
                placeholder="비번을 입력해 주세요"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>입사일</td>
            <td>
              <input
                type="date"
                id="date"
                className="form-control"
                style={{ width: '250px' }}
                placeholder="입사일을 입력해 주세요"
                value={newDate}
                onChange={(e) => setNewDate(e.target.value)}
              />
            </td>
          </tr>
          {auth?.roles?.includes('1993') ? (
            <tr>
              <td>업무 권한</td>
              <td>
                <select
                  id="ROLES"
                  className="form-select"
                  style={{ width: '150px' }}
                  onChange={handleSelectRole}
                >
                  <option value="선택">선택</option>
                  <option value='["1964"]'>Normal</option>
                  <option value='["1964", "1996"]'>Editor</option>
                  <option value='["1964", "1996", "1993"]'>Admin</option>
                </select>
              </td>
            </tr>
          ) : (
            <></>
          )}
          <tr>
            <td colSpan="2">
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  width: '100px',
                  marginLeft: '75px',
                  marginTop: '50px',
                  height: '35px',
                }}
                onClick={handleSave}
              >
                저장
              </button>
              <Link
                to="/employee"
                className="btn btn-success"
                style={{
                  width: '100px',
                  marginLeft: '400px',
                  marginTop: '50px',
                  height: '35px',
                }}
              >
                목록
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EmployeeNew;
