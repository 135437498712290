import { useState, useEffect } from 'react';
import axios from 'axios';
// import axios, { HttpStatusCode } from 'axios';
import moment from 'moment';

import Fullcalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
// import ko from '@fullcalendar/core/locales/ko';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './Schedule.css';
// import { disableCursor } from '@fullcalendar/core/internal';

function Schedule() {
  const today = new Date();
  // Getting full month name (e.g. "September")
  const month = today.toLocaleString('en-US', { month: 'long' });
  const year = today.getFullYear();
  const monthyear = month + '\u00A0' + year;
  const intmonth = today.getMonth() + 1;

  const [externalEvents, setExternalEvents] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [calendarClick, setCalendarClick] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(intmonth);
  const [dateTitle, setDateTitle] = useState(monthyear);
  // const [eventList, setEventList] = useState([]);
  const [holidaysList, setHolidaysList] = useState([]);

  // const [selectedValue, setSelectedValue] = useState('');

  // const [time, setTime] = useState('10:00');

  const [show, setShow] = useState(false);

  const [newEvent, setNewEvent] = useState({
    docunum: '',
    bldname: '',
    start: '',
    end: '',
    time: '',
    monthid: '',
    gwangye: '',
    sobangseo: '',
    plan: '',
    report: 'yet',
  });

  // 기입력 점검 일정 불러오기 (calendar)
  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/test')
      .then((res) => {
        setCalendarEvents(res.data);
      })
      .catch((err) => console.log(err));
  }, [setCalendarEvents, setDateTitle, currentMonth]);

  // 점검 대상처 불러오기 (external)
  useEffect(() => {
    axios
      .get(
        `http://221.143.168.230:3500/building/externalevents?dt=${dateTitle}&cm=${currentMonth}`
      )
      .then((res) => setExternalEvents(res.data))
      .catch((err) => console.log(err));
  }, [dateTitle, currentMonth]);

  // external drop을 위해
  useEffect(() => {
    let draggableEl = document.getElementById('external-events');
    new Draggable(draggableEl, {
      itemSelector: '.item-class',
      eventData: function (eventEl) {
        return {
          id: eventEl.dataset.id,
          title: eventEl.getAttribute('title'),
        };
      },
    });
  }, []);

  const myevents = calendarEvents.map((c) => ({
    id: c.DocuNum,
    // ttime: c.TTime,
    title: String(c.TTime).substring(0, 5) + ' ' + c.BLD_Name,
    start: c.Start,
    end: moment(c.End).add(1, 'days').format('YYYY-MM-DD'),
    allDay: true,
    monthid: c.monthID,
    // eventColor: c.Color,
    color: c.Plan === 'plan' ? '#ff7f00' : 'green', //c.Color,
  }));

  // 공휴일 정보 불러오기
  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/test/holidays-all')
      .then((res) => {
        setHolidaysList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // 공휴일을 event에 추가
  holidaysList.map((h) =>
    myevents.push({
      title: h.Title,
      start: h.Start,
      color: 'pink', //h.Color,
      // background: 'yellow',
      allDay: true,
      display: 'background',
      textColor: 'blue',
    })
  );

  // 보고서 제출 마감일에서 공휴일을 제외하기 위해
  const myholidays = holidaysList.map((h) => ({
    start: h.Start,
    title: h.Title,
    textColor: 'red',
  }));

  //handle event receive
  const handleCalendarDrop = async (e) => {
    // e.preventDefault();  여기서 사용하면 에러 발생!!!
    // console.log(e.event, e.event.backgroundColor, e.event.id, e.event.start);
    if (e.event.backgroundColor === 'green') {
      alert('선택한 event는 이미 점검이 완료된 대상처입니다.');
    } else {
      let date = new Date(e.event.start);

      var resultDate10 = addWeekdays(date, 10);
      var resultDate20 = addWeekdays(date, 20);

      const afterDrop = {
        // title, monthid는 안 변하므로
        start: moment(e.event.start).format('YYYY-MM-DD'),
        end: moment(e.event.start).format('YYYY-MM-DD'),
        gwangye: moment(resultDate10).format('YYYY-MM-DD'),
        sobangseo: moment(resultDate20).format('YYYY-MM-DD'),
      };

      try {
        await axios.put(
          `http://221.143.168.230:3500/test/calendar-drop/${e.event.id}`,
          afterDrop
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleClose = () => {
    setCalendarClick(false);
    setShow(false);
  };

  const handleEventDelete = async (e) => {
    e.preventDefault();
    // console.log(e.currentTarget.id);
    if (window.confirm('선택한 대상처를 달력에서 삭제하겠습니까?')) {
      // testschedule에서 해당 정보 삭제
      try {
        await axios.delete(
          `http://221.143.168.230:3500/test/calendarevent-delete/${e.currentTarget.id}`
        );
      } catch (err) {
        console.log(err);
      }

      // building에서 TestMonth 삭제
      try {
        await axios.put(
          `http://221.143.168.230:3500/building/testmonth-blank/${e.currentTarget.id}`
        );
      } catch (err) {
        console.log(err);
      }

      // testschedule의 점검 일정 불러오기 (calendar)
      axios
        .get('http://221.143.168.230:3500/test')
        .then((res) => setCalendarEvents(res.data))
        .catch((err) => console.log(err));

      // external을 update 하기 (2024년7월 calendar에 등록된 대상처 제외)
      axios
        .get(
          `http://221.143.168.230:3500/building/externalevents?dt=${dateTitle}&cm=${currentMonth}`
        )
        .then((res) => setExternalEvents(res.data))
        .catch((err) => console.log(err));
    }

    setCalendarClick(false);
    setShow(false);
  };

  const handleSelectedEvent = (info) => {
    setCalendarClick(true);
    // calendar에 정보 뿌리기 위해 click한 event에 대한 정보 불러오기
    axios
      .get('http://221.143.168.230:3500/test/' + info.event.id)
      .then((res) => {
        // console.log(res.data[0].Plan);
        // setNewEvent(res.data[0]);
        setNewEvent({
          docunum: res.data[0].DocuNum,
          bldname: res.data[0].BLD_Name,
          start: moment(res.data[0].Start).format('YYYY-MM-DD'),
          end: moment(res.data[0].End).format('YYYY-MM-DD'),
          time: res.data[0].TTime, //moment(res.data[0].TTime).format('HH:mm:ss'),
          monthid: res.data[0].monthID,
          plan: res.data[0].Plan,
          // color: res.data[0].Color,
        });
      })
      .catch((err) => console.log(err));

    // setNewEvent({
    //   id: info.event.id,
    //   bldname: info.event.title,
    //   start: moment(info.event.start).format('YYYY-MM-DD'),
    //   end: moment(info.event.end).format('YYYY-MM-DD'),
    //   time: moment(info.event.time).format('HH:mm:ss'),
    //   monthid: info.event.monthid,
    //   plan: info.event.plan,
    //   color: info.event.color,
    // });
    // console.log(newEvent);
    setShow(true);
  };

  // function addDays(date, days) {
  //   const clone = new Date(date);
  //   clone.setDate(date.getDate() + days);
  //   return clone;
  // }

  const handleExternalDrop = async (info) => {
    // let date = new Date(
    //   info.date.getFullYear(),
    //   info.date.getMonth(),
    //   info.date.getDate(),
    //   10,
    //   0,
    //   0
    // );
    let date = new Date(
      info.date.getFullYear(),
      info.date.getMonth(),
      info.date.getDate()
    );

    var resultDate10 = addWeekdays(date, 10);
    var resultDate20 = addWeekdays(date, 20);

    setNewEvent({
      docunum: info.draggedEl.getAttribute('data-id'),
      bldname: info.draggedEl.title,
      start: moment(date).format('YYYY-MM-DD'),
      end: moment(date).format('YYYY-MM-DD'),
      time: '10:00:00',
      monthid: dateTitle,
      gwangye: moment(resultDate10).format('YYYY-MM-DD'),
      sobangseo: moment(resultDate20).format('YYYY-MM-DD'),
      plan: 'plan',
      report: 'yet',
      // color: '#ff7f00',
      // rcolor: '#ff7f00',
    });

    // console.log(moment(date).format('HH:mm:ss'));
    // setServicePlan(true);
    // setSelectedValue('plan');
    setShow(true);
  };

  function addWeekdays(date, days) {
    date = moment(date);
    while (days >= 0) {
      var duplicated = false;
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        //평일

        //해당일이 공휴일 목록에 있는지 확인 후 없으면 -1
        for (let i = 0; i < myholidays.length; i++) {
          if (
            moment(date).format('YYYY-MM-DD') ===
            moment(myholidays[i].start).format('YYYY-MM-DD')
          ) {
            duplicated = true;
          }
        }
        if (duplicated !== true) {
          days -= 1;
        }
      }
      date = date.add(1, 'days');
    }
    date = date.add(-1, 'days');

    return date;
  }

  const handleChange = (e) => {
    let date = new Date(e.target.value);

    setNewEvent({
      ...newEvent,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === 'end') {
      var resultDate10 = addWeekdays(date, 10);
      var resultDate20 = addWeekdays(date, 20);

      setNewEvent((prev) => ({
        ...prev,
        gwangye: moment(resultDate10).format('YYYY-MM-DD'),
      }));
      setNewEvent((prev) => ({
        ...prev,
        sobangseo: moment(resultDate20).format('YYYY-MM-DD'),
      }));
    }
    // setNewEvent((prev) => ({
    //   ...prev,
    //   start: moment(info.event.start).format('YYYY-MM-DD HH:mm:ss'),
    // }));
    // setNewEvent((prev) => ({
    //   ...prev,
    //   end: moment(info.event.end).format('YYYY-MM-DD HH:mm:ss'),
    // }));

    // setNewEvent({
    //   ...newEvent,
    //   gwangye: moment(addDays(date, 10)).format('YYYY-MM-DD'), //주말 공휴일 계산 필요
    //   // sobangseo: moment(addDays(date, 20)).format('YYYY-MM-DD') //주말 공휴일 계산 필요
    // });
    // setNewEvent({
    //   ...newEvent,
    //   // gwangye: moment(addDays(date, 10)).format('YYYY-MM-DD'), //주말 공휴일 계산 필요
    //   sobangseo: moment(addDays(date, 20)).format('YYYY-MM-DD'), //주말 공휴일 계산 필요
    // });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    console.log(newEvent);
    // testschedule에 변경사항 저장
    try {
      await axios.put(`http://221.143.168.230:3500/test/${newEvent.id}`, newEvent);
    } catch (err) {
      console.log(err);
    }

    // calendar에 정보 뿌리기 위해 data 불러오기
    axios
      .get('http://221.143.168.230:3500/test')
      .then((res) => setCalendarEvents(res.data))
      .catch((err) => console.log(err));

    setShow(false);
  };

  // external에서 drop 후 저장
  const handleExternalSave = async (e) => {
    e.preventDefault();

    // testschedule에 external 정보 저장
    try {
      await axios.post('http://221.143.168.230:3500/test', newEvent);
    } catch (err) {
      console.log(err);
    }

    try {
      await axios.put(
        `http://221.143.168.230:3500/building/externaldrop/${newEvent.docunum}`,
        newEvent
      );
    } catch (err) {
      console.log(err);
    }
    // calendar에 정보 뿌리기 위해 data 불러오기
    axios
      .get('http://221.143.168.230:3500/test')
      .then((res) => setCalendarEvents(res.data))
      .catch((err) => console.log(err));

    // external을 update 하기 (2024년7월 입력된 대상처 제외)
    axios
      .get(
        `http://221.143.168.230:3500/building/externalevents?dt=${dateTitle}&cm=${currentMonth}`
      )
      .then((res) => setExternalEvents(res.data))
      .catch((err) => console.log(err));

    setShow(false);
  };

  // const handleMonth = (e) => {
  //   setCurrentMonth(e.view.activeEnd.getMonth());
  //   setDateTitle(e.view.title);
  // };

  const handleResizeEvent = async (e) => {
    // e.preventDefault(); 여기서 사용하면 에러 발생!!!
    let date = new Date(
      moment(e.event.end).add(-1, 'days').format('YYYY-MM-DD')
    );

    var resultDate10 = addWeekdays(date, 10);
    var resultDate20 = addWeekdays(date, 20);
    console.log(
      moment(e.event.start).format('YYYY-MM-DD'),
      moment(date).format('YYYY-MM-DD')
    );
    const afterDrop = {
      // title, monthid는 안 변하므로
      start: moment(e.event.start).format('YYYY-MM-DD'),
      end: moment(date).format('YYYY-MM-DD'),
      gwangye: moment(resultDate10).format('YYYY-MM-DD'),
      sobangseo: moment(resultDate20).format('YYYY-MM-DD'),
    };

    try {
      await axios.put(
        `http://221.143.168.230:3500/test/calendar-drop/${e.event.id}`,
        afterDrop
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleRadioChange = (value) => {
    // setSelectedValue(value);
    setNewEvent({ ...newEvent, plan: value });

    // if (value === 'action') {
    //   setNewEvent((prev) => ({
    //     ...prev,
    //     color: 'green',
    //   }));
    // } else {
    //   setNewEvent((prev) => ({
    //     ...prev,
    //     color: 'red',
    //   }));
    // }

    // console.log(newEvent.color);
  };

  const handleDateClick = (info) => {
    console.log(info, moment(info.dateStr).format('YYYY-MM-DD'));
    setNewEvent({
      id: 'none',
      title: '',
      start: info.dateStr,
      end: info.dateStr,
      // start: moment(info.dateStr).format('YYYY-MM-DD'),
      // end: moment(info.dateStr).format('YYYY-MM-DD'),
    });

    setShow(true);
  };

  const handleHoliday = () => {
    alert('aaa');
  };

  return (
    <div className="d-flex">
      <div
        className="d-flex flex-column mx-4 mt-3"
        style={{
          width: '15%',
          margin: '6px 0',
          padding: '0 10px',
          border: '1px solid #ccc',
          background: '#eee',
        }}
      >
        <span
          style={{
            margin: '8px 0',
            fontSize: '18px',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          점검대상처
        </span>
        <span
          style={{
            margin: '3px 0',
            fontSize: '15px',
            textAlign: 'center',
          }}
        >
          🟡 월관리, 🟢 자체점검
        </span>
        <div id="external-events">
          {externalEvents.map((e) => (
            <div
              style={{
                margin: '6px 0',
                paddingLeft: '7px',
                fontSize: '14px',
                color: 'white',
                background: '#3788D8',
                cursor: 'move',
                borderRadius: '3px',
                border: '1px solid #3a87ad',
              }}
              title={
                currentMonth === e.Jakdong
                  ? '[작]' + e.BLD_Name
                  : '[종]' + e.BLD_Name //'\u00A0'
              }
              // title={e.BLD_Name}
              data-id={e.DocuNum}
              key={e.DocuNum}
              className="item-class fc-daygrid-event"
              // className="item-class fc-h-event fc-daygrid-event  fc-daygrid-block-event"
              // fc-h-event fc-daygrid-block-event 역할???
            >
              <div className="d-flex">
                {e.Service === 1 ? <span>🟡</span> : <span>🟢</span>}
                {currentMonth === e.Jakdong ? (
                  <div>
                    <span
                      style={{
                        color: 'white',
                        background: 'blue',
                        padding: '0 2px',
                        borderRadius: '8px',
                      }}
                    >
                      작
                    </span>
                    <span style={{ marginLeft: '4px' }}>{e.BLD_Name}</span>
                  </div>
                ) : (
                  <div>
                    <span
                      style={{
                        color: 'white',
                        background: 'red',
                        padding: '0 2px',
                        borderRadius: '8px',
                      }}
                    >
                      종
                    </span>
                    <span style={{ marginLeft: '4px' }}>{e.BLD_Name}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 mr-4" style={{ width: '85%' }}>
        <Fullcalendar
          plugins={[
            dayGridPlugin, // timeGridPlugin,
            listPlugin,
            interactionPlugin,
          ]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,listMonth', //,timeGridWeek,timeGridDay
          }}
          // initialEvents={INITIAL_EVENTS}
          // locale={ko}

          editable={true} // 이동, 연장 등
          droppable={true}
          selectable={true}
          events={myevents}
          eventDrop={handleCalendarDrop}
          eventClick={handleSelectedEvent} //calendar 내 대상처 선택
          eventResize={handleResizeEvent}
          drop={handleExternalDrop}
          dateClick={handleDateClick} //data 입력을 위한 날짜 클릭
          // datesSet={handleMonth}
          datesSet={(e) => {
            let intmonthnum = e.view.activeEnd.getMonth();

            if (intmonthnum === 0) {
              intmonthnum = 12;
            } else {
              intmonthnum = e.view.activeEnd.getMonth();
            }
            setCurrentMonth(intmonthnum);
            setDateTitle(e.view.title);
          }}
          eventTimeFormat={'HH:mm'}
          displayEventTime={true}
          contentHeight={600}
        />
      </div>
      <Modal
        size="lg" //"sm lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{newEvent.bldname}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td style={{ width: '150px' }}>시작일</td>
                  <td>
                    <input
                      type="date"
                      name="start"
                      className="form-control"
                      style={{ width: '150px' }}
                      value={newEvent.start}
                      // onChange={handleChange}
                      disabled
                    />
                  </td>
                  <td style={{ width: '120px' }}>점검시작 시간</td>
                  <td>
                    <input
                      type="time"
                      name="time"
                      className="form-control"
                      style={{ width: '200px' }}
                      value={newEvent.time}
                      onChange={(e) => {
                        setNewEvent({
                          ...newEvent,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>완료일</td>
                  <td colSpan="3">
                    <input
                      type="date"
                      name="end"
                      className="form-control"
                      style={{ width: '150px' }}
                      value={newEvent.end}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td>점검진행 상태</td>
                  <td colSpan="3">
                    <div>
                      <input
                        type="radio"
                        value="plan"
                        name="testaction"
                        checked={newEvent.plan === 'plan'}
                        onChange={() => handleRadioChange('plan')}
                      />
                      점검계획
                      <input
                        type="radio"
                        value="action"
                        name="testaction"
                        style={{ marginLeft: '80px' }}
                        checked={newEvent.plan === 'action'}
                        // disabled={calendarClick ? false : true}
                        onChange={() => handleRadioChange('action')}
                      />
                      점검완료
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td>
                  {calendarClick ? (
                    <button
                      type="submit"
                      className="btn btn-danger"
                      // style={{ marginRight: '550px' }}
                      id={newEvent.docunum}
                      // onClick={handleEventDelete}
                    >
                      삭제
                    </button>
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  {calendarClick ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id={newEvent.id}
                      onClick={handleUpdate}
                    >
                      저장
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id={newEvent.id}
                      onClick={handleExternalSave}
                    >
                      저장
                    </button>
                  )}
                </td>
                <td>
                  <Button variant="secondary" onClick={handleClose}>
                    닫기
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
          {/* <div className="d-flex flex-row">
            {calendarClick ? (
              <Button
                variant="secondary"
                style={{ justifyItems: 'flex-start' }}
                onClick={handleDelete}
              >
                삭제
              </Button>
            ) : (
              <></>
            )}
            <Button variant="secondary" onClick={handleClose}>
              취소
            </Button>
            <Button variant="primary" onClick={saveEvent}>
              저장
            </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Schedule;
