import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CgAttachment } from 'react-icons/cg';
import useAuth from '../Users/useAuth';

function EmployeeTable(props) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);

  const handleClose = () => {
    setShow(false);
  };

  function handleShow(info) {
    // setShow(true);
    // handleEmployee(info);
    navigate(`/employee/update/${info}`);
  }

  const handleEmployee = async (info) => {
    axios
      .get('http://221.143.168.230:3500/employee/' + info)
      .then((res) => setList(res.data[0]))
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setList((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    // navigate('/employee/update');
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (window.confirm('선택된 직원을 퇴사 처리 하겠습니까?')) {
      try {
        await axios.delete(
          `http://221.143.168.230:3500/employee/${e.currentTarget.id}`
        );
        handleClose();
        props.stateRefresh();
        navigate('/employee');
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="container">
      <table className="table mx-auto">
        <thead className="thead-light">
          <tr>
            <th style={{ width: '300px', textAlign: 'center' }}>이름</th>
            <th style={{ width: '300px', textAlign: 'center' }}>직위</th>
            <th style={{ width: '300px', textAlign: 'center' }}>입사일</th>
            {auth?.roles?.includes('1993') ? (
              <th style={{ width: '300px', textAlign: 'center' }}>ROLES</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {props.employeeList.map((e, index) => (
            <tr key={index} className="border-bottom">
              <td
                style={{
                  textAlign: 'center',
                  fontSize: '17px',
                  fontWeight: '600',
                  color: 'blue',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleShow(e.ID);
                }}
              >
                {e.Username}
              </td>
              <td style={{ textAlign: 'center' }}>{e.Position}</td>
              <td style={{ textAlign: 'center' }}>
                {moment(e.NewDate).format('YYYY-MM-DD')}
              </td>
              {auth?.roles?.includes('1993') ? (
                <td style={{ textAlign: 'center' }}>{e.ROLES}</td>
              ) : (
                <></>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        size="lg" //"sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>직원 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td style={{ width: '20%' }}>이름</td>
                  <td style={{ width: '50%' }}>{list.Username}</td>
                </tr>
                <tr>
                  <td>직위</td>
                  <td>{list.Position}</td>
                </tr>
                <tr>
                  <td>비번</td>
                  <td>{list.Pword}</td>
                </tr>
                <tr>
                  <td>입사일</td>
                  <td>{list.NewDate}</td>
                </tr>
                <tr>
                  <td>구분</td>
                  <td>{list.ROLES}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>
                    <button
                      type="submit"
                      className="btn btn-danger"
                      style={{ marginRight: '450px' }}
                      id={list.ID}
                      onClick={handleDelete}
                    >
                      삭제
                    </button>
                  </td>
                  <td>
                    {/* <Button
                      id={list.ID}
                      variant="primary"
                      onClick={handleUpdate}
                    >
                      수정
                    </Button> */}
                    <Link
                      to={`/employee/update/${list.ID}`}
                      className="btn btn-success mr-5"
                    >
                      수정
                    </Link>
                  </td>
                  <td>
                    <Button
                      // style={{ marginLeft: '20px' }}
                      variant="secondary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EmployeeTable;
