import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BuildingPage from './BuildingPage';
import BuildingTable from './BuildingTable';

function BuildingMain() {
  const [selected, setSelected] = useState('');
  const [searchText, setSearchText] = useState('');
  const [buildingList, setBuildingList] = useState([]);

  const [page, setPage] = useState(1);
  const limit = 20;

  function stateRefresh() {
    const sql = `http://221.143.168.230:3500/building`;
    axios
      .get(sql)
      .then((res) => setBuildingList(res.data))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (selected) {
      setPage(1);
      const url = `http://221.143.168.230:3500/building?select=${selected}`;
      axios
        .get(url)
        .then((res) => setBuildingList(res.data))
        .catch((err) => console.log(err));
    } else {
      stateRefresh();
    }
  }, [selected]);

  const CurrentBuildingList = (buildingList) => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const slicedList = buildingList.slice(startIndex, endIndex);
    return slicedList;
  };

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const handleSearch = async (e) => {
    if (searchText.length === 0) {
      alert('검색어를 입력하세요...');
    } else {
      e.preventDefault();
      const url = `http://221.143.168.230:3500/building/search?q=${searchText}`;
      await axios
        .get(url)
        .then((res) => {
          setBuildingList(res.data);
          setSearchText('');
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="mx-auto" style={{ width: '85%' }}>
      <div className="d-flex mt-4">
        <span
          style={{
            width: '50px',
            marginTop: '10px',
            fontSize: '15px',
            fontWeight: '600',
          }}
        >
          점검월
        </span>
        <span style={{ marginLeft: '15px', width: '120PX' }}>
          <select className="form-select" id="month" onChange={handleChange}>
            <option value="0">전체</option>
            <option value="1">1월</option>
            <option value="2">2월</option>
            <option value="3">3월</option>
            <option value="4">4월</option>
            <option value="5">5월</option>
            <option value="6">6월</option>
            <option value="7">7월</option>
            <option value="8">8월</option>
            <option value="9">9월</option>
            <option value="10">10월</option>
            <option value="11">11월</option>
            <option value="12">12월</option>
          </select>
        </span>
        <span
          style={{
            width: '100px',
            marginLeft: '20px',
            marginTop: '8px',
            fontSize: '15px',
            fontWeight: '600',
          }}
        >
          조회 대상처:
        </span>
        <span
          className="text-success"
          style={{
            marginTop: '5px',
            marginLeft: '10px',
            fontSize: '18px',
            fontWeight: '600',
          }}
        >
          {String(buildingList.length)}
        </span>
        <form onSubmit={handleSearch} className="d-inline-flex">
          <input
            type="text"
            id="search"
            className="form-control"
            style={{ marginLeft: '500px', width: '180px', height: '35px' }}
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button
            type="submit"
            className="btn btn-warning"
            style={{ marginLeft: '10px', width: '80px', height: '35px' }}
          >
            조회
          </button>
          <Link
            to="/bldregister"
            className="btn btn-primary"
            style={{ marginLeft: '30px', width: '120px', height: '35px' }}
          >
            대상처 등록
          </Link>
        </form>
      </div>
      <hr className="mt-4" />
      <div className="mx-auto mt-3">
        <BuildingTable
          buildingList={CurrentBuildingList(buildingList)}
          selected={selected}
          page={page}
          stateRefresh={stateRefresh}
        />
        <BuildingPage
          total={buildingList.length}
          page={page}
          setPage={setPage}
          limit={limit}
        />
      </div>
    </div>
  );
}

export default BuildingMain;
