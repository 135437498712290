import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import Fullcalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
// import ko from '@fullcalendar/core/locales/ko';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './Schedule.css';

function Report() {
  const [holidays, setHolidays] = useState([]);
  const [reportCalendar, setReportCalendar] = useState([]);
  const [show, setShow] = useState(false);

  const [newEvent, setNewEvent] = useState({
    docunum: '',
    bldname: '',
    start: '',
    end: '',
    time: '',
    monthid: '',
    gwangye: '',
    sobangseo: '',
    plan: '',
    report: '',
  });

  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/test')
      .then((res) => {
        setReportCalendar(res.data);
      })
      .catch((err) => console.log(err));
  }, [setReportCalendar]);

  const myevents = reportCalendar.map((r) => ({
    id: r.DocuNum,
    title: r.BLD_Name,
    start: r.Sobangseo,
    color: r.Report === 'yet' ? '#ff7f00' : 'green',
    allDay: true,
  }));

  // 공휴일 정보 불러오기
  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/test/holidays')
      .then((res) => {
        setHolidays(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  holidays.map((h) =>
    myevents.push({
      title: h.Title,
      start: h.Start,
      color: 'pink',
      allDay: true,
      display: 'background',
    })
  );

  const handleCalendarDrop = async (e) => {
    // e.preventDefault(); 여기서 사용하면 에러 발생!!!
    console.log(e.event.start);
    if (e.oldEvent.start <= e.event.start) {
      alert('현재 날짜보다 이전으로만 이동가능합니다.');
    } else {
      const afterDrop = {
        sobangseo: moment(e.event.start).format('YYYY-MM-DD'),
        report: 'done',
      };

      try {
        await axios.put(
          `http://221.143.168.230:3500/test/report-drop/${e.event.id}`,
          afterDrop
        );
      } catch (err) {
        console.log(err);
      }

      axios
        .get('http://221.143.168.230:3500/test')
        .then((res) => {
          setReportCalendar(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleEventClick = (info) => {
    axios
      .get('http://221.143.168.230:3500/test/' + info.event.id)
      .then((res) => {
        setNewEvent({
          docunum: res.data[0].DocuNum,
          bldname: res.data[0].BLD_Name,
          start: moment(res.data[0].Start).format('YYYY-MM-DD'),
          end: moment(res.data[0].End).format('YYYY-MM-DD'),
          gwangye: moment(res.data[0].Gwangye).format('YYYY-MM-DD'),
          sobangseo: moment(res.data[0].Sobangseo).format('YYYY-MM-DD'),
          report: res.data[0].Report,
        });
      })
      .catch((err) => console.log(err));

    // setNewEvent({
    //   docunum: info.event.id,
    //   bldname: info.event.title,
    //   sobangseo: moment(info.event.start).format('YYYY-MM-DD'),
    //   rcolor: info.event.backgroundColor,
    // });

    setShow(true);
  };

  const handleReportUpdate = async (e) => {
    e.preventDefault();

    // testschedule에 보고서 제출일 (sobanseo), 색깔 (rcolor) 저장
    try {
      await axios.put(
        `http://221.143.168.230:3500/test/report-update/${newEvent.docunum}`,
        newEvent
      );
    } catch (err) {
      console.log(err);
    }

    // calendar에 정보 뿌리기 위해 data 불러오기
    axios
      .get('http://221.143.168.230:3500/test')
      .then((res) => {
        setReportCalendar(res.data);
      })
      .catch((err) => console.log(err));

    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="mt-4 mx-5">
      <div>
        <Fullcalendar
          plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,listMonth', //,timeGridWeek,timeGridDay
          }}
          titleFormat={function (date) {
            // title 설정
            return (
              date.date.year +
              '년 ' +
              (date.date.month + 1) +
              '월 (소방서 보고서 제출)'
            );
          }}
          // initialEvents={INITIAL_EVENTS}
          // locale={ko}
          editable={true}
          droppable={true}
          selectable={true}
          eventDrop={handleCalendarDrop}
          events={myevents}
          //   eventDrop={handleDrop}
          eventClick={handleEventClick} //event 클릭
          contentHeight={600}
        />
      </div>
      <Modal
        size="lg" //"sm lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>보고서 제출 (소방서)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>대상처</td>
                  <td>{newEvent.bldname}</td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>점검일</td>
                  <td>
                    <span>{newEvent.start}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px' }}>관계인 전달</td>
                  <td>
                    <span>{newEvent.gwangye}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '150px',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    소방서 마감일
                  </td>
                  <td
                    style={{
                      color: 'red',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    <span>{newEvent.sobangseo}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <table className="table table-borderless">
            <tbody>
              <tr>
                {newEvent.rcolor === 'green' ? ( // 보고서 색깔이 초록인 경우 저장 필요 없으므로 버튼 숨기기
                  <td></td>
                ) : (
                  <td>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id={newEvent.id}
                      onClick={handleReportUpdate}
                    >
                      저장
                    </button>
                  </td>
                )}
                <td>
                  <Button variant="secondary" onClick={handleClose}>
                    취소
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Report;
