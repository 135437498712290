// import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import axios from 'axios';
import moment from 'moment';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import useAuth from '../Users/useAuth';

function BuildingTable(props) {
  // const { auth } = useAuth();

  // const navigate = useNavigate();
  // const [show, setShow] = useState(false);
  // const [bldList, setBldList] = useState([]);

  // const handleClose = () => {
  //   setShow(false);
  // };

  // const handleDelete = async (e) => {
  //   e.preventDefault();
  //   if (window.confirm('해당 글을 삭제하겠습니까?')) {
  //     try {
  //       await axios.delete(
  //         `http://221.143.168.230:3500/building/delete/${e.currentTarget.id}`
  //       );
  //       // handleClose();
  //       props.stateRefresh();
  //       navigate('/building');
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  // const downloadPdf = async (filename) => {
  //   console.log(filename);
  //   await axios
  //     .get(`http://221.143.168.230:3500/board/download?q=${filename}`, {
  //       responseType: 'blob', // important
  //     })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', filename);
  //       document.body.appendChild(link);
  //       link.click();
  //     });
  //   handleClose();
  //   navigate('/building');
  // };

  return (
    <div className="mx-auto">
      <table className="table mx-auto">
        <thead>
          <tr style={{ height: '40px' }}>
            <th style={{ width: '80px', textAlign: 'center' }}>점검</th>
            <th style={{ width: '100px', textAlign: 'center' }}>사용승인일</th>
            <th style={{ width: '100px', textAlign: 'center' }}>건축허가일</th>
            <th style={{ width: '100px', textAlign: 'center' }}>등급</th>
            <th style={{ width: '100px', textAlign: 'center' }}>관리</th>
            <th style={{ width: '250px', textAlign: 'center' }}>건물명</th>
            <th style={{ width: '350px', textAlign: 'center' }}>주소</th>
            <th style={{ width: '100px', textAlign: 'center' }}>층수</th>
            <th style={{ width: '130px', textAlign: 'center' }}>연면적(m²)</th>
            <th style={{ width: '90px', textAlign: 'center' }}>담당자</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '14px' }}>
          {props.buildingList.map((b, index) => (
            <tr
              key={index}
              className="border-bottom"
              style={{ textAlign: 'center' }}
            >
              <td style={{ height: '35px' }}>
                {b.Jakdong > 0 ? (
                  b.Jonghap > 0 ? (
                    <>
                      <span
                        style={{
                          fontSize: '14px',
                          color: 'white',
                          background: '#3788D8',
                          borderRadius: '3px',
                          border: '1px solid #3788D8',
                        }}
                      >
                        작
                      </span>
                      <span
                        style={{
                          marginLeft: '5PX',
                          fontSize: '14px',
                          color: 'white',
                          background: '#d37506',
                          borderRadius: '3px',
                          border: '1px solid #d37506',
                        }}
                      >
                        종
                      </span>
                    </>
                  ) : (
                    <span
                      style={{
                        fontSize: '14px',
                        color: 'white',
                        background: '#3788D8',
                        borderRadius: '3px',
                        border: '1px solid #3788D8',
                      }}
                    >
                      작
                    </span>
                  )
                ) : b.Jonghap > 0 ? (
                  <span
                    style={{
                      fontSize: '14px',
                      color: 'white',
                      background: '#d37506',
                      borderRadius: '3px',
                      border: '1px solid #d37506',
                    }}
                  >
                    종
                  </span>
                ) : (
                  '없음'
                )}
              </td>

              {b.ADate === null ? (
                <td style={{ textAlign: 'center' }}>자료없음</td>
              ) : (
                <td>{moment(b.ADate).format('YYYY-MM-DD')}</td>
              )}

              {b.PDate === null ? (
                <td style={{ textAlign: 'center' }}>자료없음</td>
              ) : (
                <td>{moment(b.PDate).format('YYYY-MM-DD')}</td>
              )}
              <td>{b.Geub}</td>
              <td>
                <span
                  style={{
                    fontSize: '14px',
                    color: 'white',
                    background: b.Service === 1 ? '#81b622' : '#5A4FCF',
                    borderRadius: '3px',
                    border:
                      b.Service === 1
                        ? '1px solid #81b622'
                        : '1px solid #5A4FCF',
                  }}
                >
                  {b.Service === 1 ? '월' : '자'}
                </span>
              </td>
              <td style={{ textAlign: 'left' }}>
                <Link
                  to={`/building/info/${b.DocuNum}`}
                  style={{
                    cursor: 'pointer',
                    textAlign: 'left',
                    textDecoration: 'none',
                  }}
                >
                  {b.BLD_Name}
                </Link>
              </td>
              <td style={{ textAlign: 'left' }}>{b.Address1}</td>
              <td>
                {b.Dong > 1 ? (
                  <div>
                    {b.Floor + '/' + b.Basement + '층'} ({b.Dong})
                  </div>
                ) : (
                  <div>{b.Floor + '/' + b.Basement + '층'}</div>
                )}
              </td>
              <td>{b.Area1}</td>
              <td>{b.Damdang}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <Modal
        size="lg" //"sm lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontSize: '17px' }}>대상처 상세 정보 :</span>{' '}
            <span style={{ fontSize: '22px', color: 'green' }}>
              {bldList.BLD_Name}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>주소</td>
                  <td>
                    {bldList.Address2
                      ? bldList.Zip
                        ? bldList.Address1 +
                          bldList.Address2 +
                          '(우편번호' +
                          bldList.Zip +
                          ')'
                        : bldList.Address1 + bldList.Address2
                      : bldList.Zip
                      ? bldList.Address1 + '(우편번호' + bldList.Zip + ')'
                      : bldList.Address1}
                  </td>
                </tr>
                <tr>
                  <td>승인일</td>
                  <td>{bldList.ADate ? bldList.ADate : '없음'}</td>
                </tr>
                <tr>
                  <td>허가일</td>
                  <td>{bldList.PDate ? bldList.PDate : '없음'}</td>
                </tr>
                <tr>
                  <td>관리 분류</td>
                  <td>
                    {bldList.ServiceMonth === 1
                      ? bldList.ServiceTest === 1
                        ? '월관리 + 자체점검'
                        : '월관리'
                      : bldList.ServiceTest === 1
                      ? '자체관리'
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td>점검 구분</td>
                  <td>
                    {bldList.Jakdong === 0
                      ? '점검 안함'
                      : bldList.Jonghap === 0
                      ? '작동점검 대상처 (작동점검 :' + bldList.Jakdong + '월)'
                      : '종합점검 대상처 (작동점검 :' +
                        bldList.Jakdong +
                        '월 /' +
                        '종합점검 :' +
                        bldList.Jonghap +
                        '월)'}
                  </td>
                </tr>
                <tr>
                  <td>층수</td>
                  <td>
                    {'지상' +
                      bldList.Floor +
                      '층 / 지하' +
                      bldList.Basement +
                      '층'}
                  </td>
                </tr>
                <tr>
                  <td>연면적</td>
                  <td>
                    {bldList.Area === 0 ? '자료없음' : bldList.Area + 'm2'}
                  </td>
                </tr>
                <tr>
                  <td>건물 용도</td>
                  <td>{bldList.Yongdo ? bldList.Yongdo : '없음'}</td>
                </tr>
                {bldList.Yongdo ? (
                  <tr>
                    <td>세대수</td>
                    <td>{bldList.Sede ? bldList.Sede : '없음'}</td>
                  </tr>
                ) : (
                  ''
                )}
                <tr>
                  <td>다중업체</td>
                  <td>{bldList.Dajung ? bldList.Dajung : '없음'}</td>
                </tr>
                <tr>
                  <td>건물관리업체</td>
                  <td>{bldList.BFOS ? bldList.BFOS : '없음'}</td>
                </tr>
                <tr>
                  <td>건축물대장</td>
                  <td>
                    {bldList.File ? (
                      <div className="d-flex">
                        <span>{bldList.File}</span>
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: '50px', height: '35px' }}
                          id={bldList.File}
                          onClick={() => downloadPdf(bldList.File)}
                        >
                          Download
                        </button>
                      </div>
                    ) : (
                      '없음'
                    )}
                  </td>
                </tr>
                <tr>
                  <td>담당자</td>
                  <td>{bldList.Damdang ? bldList.Damdang : '미지정'}</td>
                </tr>
                <tr>
                  <td>계약자1</td>
                  <td>
                    {bldList.Person1
                      ? bldList.Person1 + bldList.Phone1 + bldList.Fax1
                      : '없음'}
                  </td>
                </tr>
                <tr>
                  <td>계약서</td>
                  <td>
                    {bldList.Contract ? (
                      <div className="d-flex">
                        <span>{bldList.Contract}</span>
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: '50px', height: '35px' }}
                          id={bldList.File}
                          onClick={() => downloadPdf(bldList.Contract)}
                        >
                          Download
                        </button>
                      </div>
                    ) : (
                      '없음'
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {auth.roles.includes('1964', 0) ? (
            <>
              <button
                type="submit"
                className="btn btn-danger"
                style={{ marginRight: '400px' }}
                id={bldList.DocuNum}
                onClick={handleDelete}
              >
                계약해지
              </button>
              <Link
                to={`/building/update/${bldList.DocuNum}`}
                className="btn btn-warning mr-5"
              >
                수정
              </Link>
            </>
          ) : (
            <></>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default BuildingTable;
