import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CgAttachment } from 'react-icons/cg';

function BoardTable(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);

  // const [loader, setLoader] = useState(false);
  // const [error, setError] = useState('');

  const handleClose = () => {
    setShow(false);
  };

  function handleShow(info) {
    setShow(true);
    handleClick(info);
  }

  const handleClick = async (info) => {
    axios
      .get('http://221.143.168.230:3500/board/' + info)
      .then((res) => setList(res.data[0]))
      .catch((err) => console.log(err));
  };

  // const handleChange = (e) => {
  //   setList((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  // };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await axios.put(
  //       `http://221.143.168.230:3500/board-update/${e.currentTarget.id}`,
  //       list
  //     );
  //     handleClose();
  //     props.stateRefresh();
  //     navigate('/board');
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const handleDelete = async (e) => {
  //   e.preventDefault();
  //   if (window.confirm('해당 글을 삭제하겠습니까?')) {
  //     try {
  //       await axios.delete(
  //         `http://221.143.168.230:3500/board-delete/${e.currentTarget.id}`
  //       );
  //       handleClose();
  //       props.stateRefresh();
  //       navigate('/board');
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  const downloadPdf = async (filename) => {
    // console.log(filename);
    await axios
      .get(`http://221.143.168.230:3500/board/download?q=${filename}`, {
        responseType: 'blob', // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    handleClose();
    navigate('/board');
  };

  // const download = (e, filename) => {
  //   e.preventDefault();
  //   axios
  //     .get(`http://221.143.168.230:3500/board/download?q=${filename}`, {
  //       responseType: 'blob', // important
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       FileDownload(res.data, 'download.pdf');
  //     });
  // };

  return (
    <div className="container">
      <table className="table mx-auto">
        <thead className="thead-light">
          <tr>
            <th style={{ width: '100px', textAlign: 'center' }}>번호</th>
            <th style={{ width: '600px', textAlign: 'center' }}>제목</th>
            <th style={{ width: '200px', textAlign: 'center' }}>작성일</th>
            <th style={{ width: '200px', textAlign: 'center' }}>작성자</th>
            <th style={{ width: '100px', textAlign: 'center' }}>첨부</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '14px' }}>
          {props.boardList.map((item, index) => (
            <tr key={index} className="border-bottom">
              <td style={{ width: '100px', textAlign: 'center' }}>{item.ID}</td>
              <td
                style={{ width: '700px', cursor: 'pointer' }}
                onClick={() => {
                  handleShow(item.ID);
                }}
              >
                {item.Title}
              </td>
              <td style={{ textAlign: 'center' }}>
                {moment(item.WDate).format('YYYY-MM-DD')}
              </td>
              <td style={{ textAlign: 'center' }}>{item.Writer}</td>
              <td style={{ textAlign: 'center' }}>
                {item.Files1 ? (
                  <div>
                    <CgAttachment />
                  </div>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        size="lg" //"sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>게시글 조회</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td style={{ width: '20%' }}>작성자</td>
                  <td colSpan="2">{list.Writer}</td>
                </tr>
                <tr>
                  <td>작성일</td>
                  <td colSpan="2">{list.WDate}</td>
                </tr>
                <tr>
                  <td>제목</td>
                  <td colSpan="2">{list.Title}</td>
                </tr>
                <tr>
                  <td>내용</td>
                  <td colSpan="2">
                    <textarea
                      type="text"
                      className="form-control"
                      style={{ width: '670px', height: '200px' }}
                      id="Contents"
                      name="Contents"
                      defaultValue={list.Contents}
                    />
                  </td>
                </tr>
                {list.Files1 ? (
                  <tr>
                    <td>첨부 #1</td>
                    <td style={{ width: '70%' }}>
                      <span>
                        {list.Files1.substring(14, list.Files1.length)}
                      </span>
                    </td>
                    <td style={{ width: '10%' }}>
                      <button
                        className="btn btn-success"
                        style={{ height: '35px' }}
                        id={list.Files1}
                        onClick={() => downloadPdf(list.Files1)}
                      >
                        Download
                      </button>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {list.Files2 ? (
                  <tr>
                    <td>첨부 #2</td>
                    <td style={{ width: '70%' }}>
                      <span>
                        {list.Files2.substring(14, list.Files2.length)}
                      </span>
                    </td>
                    <td style={{ width: '10%' }}>
                      <button
                        className="btn btn-success"
                        style={{ height: '35px' }}
                        id={list.Files2}
                        onClick={() => downloadPdf(list.Files2)}
                      >
                        Download
                      </button>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {list.Files3 ? (
                  <tr>
                    <td>첨부 #3</td>
                    <td style={{ width: '70%' }}>
                      <span>
                        {list.Files3.substring(14, list.Files3.length)}
                      </span>
                    </td>
                    <td style={{ width: '10%' }}>
                      <button
                        className="btn btn-success"
                        style={{ height: '35px' }}
                        id={list.Files3}
                        onClick={() => downloadPdf(list.Files3)}
                      >
                        Download
                      </button>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>
                    <Button
                      // style={{ marginLeft: '20px' }}
                      variant="secondary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BoardTable;
