import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuth from './useAuth';
// import Spinner from '../utils/Spinner';
import './Login.css';

function Login() {
  const { setAuth } = useAuth();

  const navigate = useNavigate();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const [logged, setLogged] = useState(true);

  const userRef = useRef();
  const errRef = useRef();

  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`http://221.143.168.230:3500/logincheck`, {
        user,
        pwd,
      });

      const username = response?.data[0]?.Username;
      const roles = response?.data[0]?.ROLES;

      if (username && roles) {
        setAuth({ username, roles, logged });
        sessionStorage.setItem(
          'usercheck',
          JSON.stringify({ username: username, roles: roles, logged: true })
        );
        setUser('');
        setPwd('');
        navigate('/');
      } else {
        sessionStorage.setItem(
          'usercheck',
          JSON.stringify({ username: '', roles: '', logged: false })
        );
        setLogged(false);
        setAuth({});
      }
    } catch (err) {
      console.log(err.response);
      if (err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    }
  };

  return (
    // <div className="auth-wrapper">
    //   <div className="auth-inner">
    <div className="container w-25">
      {/* .auth-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 50px;
} */}
      <div className="flex flex-column mt-5 shadow p-3 mb-5 bg-white rounded">
        <p
          ref={errRef}
          className={errMsg ? 'errmsg' : 'offscreen'}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <h3 style={{ margin: '20px 0' }}>Login</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <p
              htmlFor="username"
              className="text-start"
              style={{ fontSize: '18px' }}
            >
              Username
            </p>
            <input
              type="text"
              id="username"
              ref={userRef}
              onChange={(e) => setUser(e.target.value)}
              className="form-control"
              autoComplete="off"
              // placeholder="Enter your name"
              value={user}
              required
            />
          </div>
          <div className="form-group mb-3">
            <p
              htmlFor="password"
              className="text-start"
              style={{ fontSize: '18px' }}
            >
              Password
            </p>
            <input
              type="password"
              id="password"
              value={pwd}
              onChange={(e) => setPwd(e.target.value)}
              className="form-control"
              // placeholder="Enter your password"
              required
            />
          </div>
          <div className="d-grid">
            <button className="btn btn-primary mt-3">Log in</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
