import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import VehiclePage from './VehiclePage';
import VehicleTable from './VehicleTable';
import { FaCar } from 'react-icons/fa';

function VehicleMain() {
  const [vehicleList, setVehicleList] = useState([]); //회사차량
  // const [searchText, setSearchText] = useState(''); //검색어
  const [page, setPage] = useState(1); //page 1 설정
  const limit = 10; //화면 최대 갯수

  function stateRefresh() {
    setPage(1);
    const sql = `http://221.143.168.230:3500/vehicle`;
    axios
      .get(sql)
      .then((res) => setVehicleList(res.data))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    stateRefresh();
  }, []);

  const CurrentVehicleList = (boardList) => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const slicedList = boardList.slice(startIndex, endIndex);
    return slicedList;
  };

  // const handleSearch = async (e) => {
  //   e.preventDefault();
  //   var url;
  //   url = `http://221.143.168.230:3500/board?q=${searchText}`;
  //   await axios
  //     .get(url)
  //     .then((res) => {
  //       setVehicleList(res.data);
  //       setSearchText('');
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    <div className="container">
      <table className="table table-borderless mt-3">
        <tbody>
          <tr>
            <td
              style={{
                width: '20%',
                fontSize: '20px',
                fontWeight: '600',
                // paddingTop: '13px',
              }}
            >
              <FaCar /> 차량조회
            </td>
            <td style={{ width: '50%', textAlign: 'right' }}>
              <Link
                to="/vehicle/new"
                className="btn btn-primary"
                style={{ marginLeft: '30px', width: '100px', height: '35px' }}
              >
                차량 등록
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
      <hr className="mt-3" />
      <div className="mx-auto mt-3">
        <VehicleTable
          vehicleList={CurrentVehicleList(vehicleList)}
          page={page}
          stateRefresh={stateRefresh}
        />
        <VehiclePage
          total={vehicleList.length}
          page={page}
          setPage={setPage}
          limit={limit}
          siblings={1}
        />
      </div>
    </div>
  );
}

export default VehicleMain;
