import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BsFillArchiveFill, BsFillGrid3X3GapFill } from 'react-icons/bs';
import { MdCalendarMonth } from 'react-icons/md';
import { FaRegCalendarCheck } from 'react-icons/fa';

function DashBoard() {
  const [countMonth, setCountMonth] = useState(0);
  const [countJache, setCountJache] = useState(0);
  const [countJonghap, setCountJonghap] = useState(0);
  const [countJakdong, setCountJakdong] = useState(0);
  const [countNoTest, setCountNoTest] = useState(0);
  const [countGeub0, setCountGeub0] = useState(0);
  const [countGeub1, setCountGeub1] = useState(0);
  const [countGeub2, setCountGeub2] = useState(0);
  const [countGeub3, setCountGeub3] = useState(0);
  const [countGeubNo, setCountGeubNo] = useState(0);
  const [countMonthJonghap, setCountMonthJonghap] = useState(0);
  const [countMonthJakdong, setCountMonthJakdong] = useState(0);
  const [countReportYet, setCountReportYet] = useState(0);
  const [countTestPlan, setCountTestPlan] = useState(0);

  const today = new Date();
  const intmonth = today.getMonth() + 1;

  useEffect(() => {
    const sql = `http://221.143.168.230:3500/building/count`;
    axios
      .get(sql)
      .then((res) => {
        // console.log(res.data[0].Service1);
        // setCount(res.data[0]['COUNT(*)']);
        setCountMonth(res.data[0].Service1);
        setCountJache(res.data[0].Service2);
        setCountNoTest(res.data[0].NoTest);
        setCountJakdong(res.data[0].Jakdong);
        setCountJonghap(res.data[0].Jonghap);
        setCountMonthJonghap(res.data[0].MonthJonghap);
        setCountMonthJakdong(res.data[0].MonthJakdong);
        setCountGeub0(res.data[0].Geub0);
        setCountGeub1(res.data[0].Geub1);
        setCountGeub2(res.data[0].Geub2);
        setCountGeub3(res.data[0].Geub3);
        setCountGeubNo(res.data[0].GeubNo);
        // setCountReportYet(res.data[0].ReportYet);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const sql = `http://221.143.168.230:3500/test/count`;
    axios
      .get(sql)
      .then((res) => {
        setCountReportYet(res.data[0].ReportYet);
        setCountTestPlan(res.data[0].TestPlan);
      })
      .catch((err) => console.log(err));
  }, []);

  //   useEffect(() => {
  //     const sql = `http://221.143.168.230:3500/building/countmonth`;
  //     axios
  //       .get(sql)
  //       .then((res) => {
  //         setCountMonth(res.data[0]['COUNT(*)']);
  //       })
  //       .catch((err) => console.log(err));
  //   }, []);

  //   useEffect(() => {
  //     const sql = `http://221.143.168.230:3500/building/countjonghap`;
  //     axios
  //       .get(sql)
  //       .then((res) => {
  //         setCountJonghap(res.data[0]['COUNT(*)']);
  //       })
  //       .catch((err) => console.log(err));
  //   }, []);

  return (
    <div
      className="mx-auto"
      style={{
        width: '85%',
        overflowY: 'auto',
      }}
    >
      <div className="mt-4">
        <h4>전체 대상처 현황</h4>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gap: '20px',
          margin: '15px 0',
        }}
      >
        <div
          className="d-flex flex-column justify-content-around"
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
            background: '#2962ff',
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h5>월관리</h5>
            <MdCalendarMonth style={{ fontSize: '20px' }} />
          </div>
          <h4>
            {countMonth}/{countMonth + countJache}
          </h4>
        </div>
        <div
          className="d-flex flex-column justify-content-around"
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
            background: '#ff6d00',
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h5>자체관리</h5>
            <FaRegCalendarCheck style={{ fontSize: '20px' }} />
          </div>
          <h4>
            {countJache}/{countMonth + countJache}
          </h4>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gap: '20px',
          margin: '15px 0',
        }}
      >
        <div
          className="d-flex flex-column justify-content-around"
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
            background: 'green',
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h5>종합점검</h5>
            <BsFillArchiveFill style={{ fontSize: '20px' }} />
          </div>
          <h4>
            {countJonghap}/{countMonth + countJache}
          </h4>
        </div>
        <div
          className="d-flex flex-column justify-content-around"
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
            background: 'yellow',
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h5>작동점검</h5>
            <BsFillGrid3X3GapFill style={{ fontSize: '20px' }} />
          </div>
          <h4>
            {countJakdong}/{countMonth + countJache}
          </h4>
        </div>
        <div
          className="d-flex flex-column justify-content-around"
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
            background: 'red',
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h5>미점검 대상처</h5>
            <BsFillGrid3X3GapFill style={{ fontSize: '20px' }} />
          </div>
          <h4>
            {countNoTest}/{countMonth + countJache}
          </h4>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '20px',
          margin: '15px 0',
        }}
      >
        <div
          className="d-flex flex-column justify-content-around"
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
            background: '#665fd1',
          }}
        >
          <h5>소방등급 분류</h5>
          <div className="d-flex" style={{ color: 'white' }}>
            <h5>특급 - {countGeub0}, </h5>
            <h5 className="ml-4">1급 - {countGeub1},</h5>
            <h5 className="ml-4">2급 - {countGeub2},</h5>
            <h5 className="ml-4">3급 - {countGeub3},</h5>
            <h5 className="ml-4">미적용 - {countGeubNo}</h5>
          </div>
        </div>

        {/* <div
          className="d-flex flex-column justify-content-around"
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
            background: '#8b88f8',
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h5>3급</h5>
            <BsFillGrid3X3GapFill style={{ fontSize: '20px' }} />
          </div>
          <h4>
            {countGeub3}/{countMonth + countJache}
          </h4>
        </div>
        <div
          className="d-flex flex-column justify-content-around"
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
            background: '#6667ab',
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h5>기타(자료없음)</h5>
            <BsFillGrid3X3GapFill style={{ fontSize: '20px' }} />
          </div>
          <h4>
            {countGeubNo}/{countMonth + countJache}
          </h4>
        </div> */}
      </div>
      <div className="mt-5">
        <h4>
          {intmonth}월 대상처 점검 현황 [{countMonthJonghap + countMonthJakdong}
          건]
        </h4>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '20px',
          margin: '15px 0',
        }}
      >
        <div
          className="d-flex flex-column justify-content-around"
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
            background: '#665fd1',
          }}
        >
          <div className="d-flex" style={{ color: 'white' }}>
            <h5>종합점검 {countMonthJonghap}, </h5>
            <h5 className="ml-4">작동점검 {countMonthJakdong}</h5>
            <h5 className="ml-4">[잔여 대상처 {countTestPlan} ]</h5>
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-around"
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
            background: '#7085ff',
          }}
        >
          <div className="d-flex" style={{ color: 'white' }}>
            <Link
              to="/bldreport"
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <h5>보고서 미제출 : {countReportYet} 건</h5>
            </Link>
            {/* <h5 className="ml-4">미점검 건물 {countTestPlan}</h5> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;
