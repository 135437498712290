import { Navigate, Outlet } from 'react-router-dom';
import useAuth from './useAuth';
// import { all } from 'axios';

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();

  // return auth.roles.includes(allowedRoles, 0) ? (
  // return allowedRoles.find((role) => role === auth.roles) ? ( //ok
  return allowedRoles.find((role) => auth?.roles?.includes(role)) ? ( //ok
    // return auth?.roles?.find((role) => allowedRoles?.includes(role)) ? ( //nok
    <Outlet />
  ) : auth?.user ? (
    // <Navigate to="/unauthorized" state={{ from: location }} replace />
    <Navigate to="/unauthorized" />
  ) : (
    <Navigate to="/" />
  );
  // <Navigate to="/" state={{ from: location }} replace />
};

export default RequireAuth;
