import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../Users/useAuth';
import FileUploader from './FileUploader';

function BoardNew() {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  // const [writer, setWriter] = useState('');
  const [contents, setContents] = useState('');
  // const [wdate, setWdate] = useState(new Date().toLocaleDateString('en-CA'));
  const [file, setFile] = useState([]);
  const { auth } = useAuth();
  // const [files, setFiles] = useState([]);

  // const handleUpload_1file = async () => {
  //   if (!file) {
  //     try {
  //       await axios.post('http://221.143.168.230:3500/board', {
  //         title: title,
  //         contents: contents,
  //         writer: auth.username,
  //         wdate: new Date().toLocaleDateString('en-CA'),
  //       });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   } else {
  //     const formData = new FormData();
  //     formData.append('title', title);
  //     formData.append('contents', contents);
  //     formData.append('writer', auth.username);
  //     formData.append('wdate', new Date().toLocaleDateString('en-CA'));
  //     formData.append('board', file);

  //     // for (let i = 0; i < file.length; i++) {
  //     //   const item = files[i];
  //     //   formData.append('board', item);
  //     // }
  //     // console.log(file.length, formData);
  //     try {
  //       const result = await axios.post(
  //         'http://221.143.168.230:3500/board/fileupload',
  //         formData
  //       ); // file 1개
  //       // console.log(result);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   navigate('/board');
  // };

  const handleUpload = async () => {
    if (!file) {
      try {
        await axios.post('http://221.143.168.230:3500/board', {
          title: title,
          contents: contents,
          writer: auth.username,
          wdate: new Date().toLocaleDateString('en-CA'),
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('contents', contents);
      formData.append('writer', auth.username);
      formData.append('wdate', new Date().toLocaleDateString('en-CA'));

      for (let i = 0; i < file.length; i++) {
        const item = file[i];
        formData.append('board', item);
      }

      try {
        await axios.post('http://221.143.168.230:3500/board/multifile', formData);
        // console.log(result);
      } catch (error) {
        console.log(error);
      }
    }
    navigate('/board');
  };

  const onSelectFileHandler = (e) => {
    // console.log(e.target.files);
    // setFile(e.target.files[0]);
    setFile(e.target.files);
  };

  const onDeleteFileHandler = () => {};

  // const savedata1 = async () => {
  //   const formData = new FormData();

  //   formData.append('report', report);
  //   for (let i = 0; i < susingiFile.length; i++) {
  //     const item = susingiFile[i];
  //     formData.append('susingi', item);
  //   }

  //   postFiles(formData);
  //   navigate('/building');
  // };

  // const postFiles = async (formData) => {
  //   try {
  //     await axios.post('http://221.143.168.230:3500/board/fileupload', formData);
  //     // console.log(result);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="container">
      <div className="h5 font-weight-bold mt-4">게시글 작성</div>
      <table className="table table-borderless mt-4">
        <tbody>
          <tr>
            <td style={{ width: '100px' }}>
              <label htmlFor="writer">작성자 :</label>
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                style={{ width: '120px', textAlign: 'center' }}
                id="writer"
                value={auth.username}
                disabled
              />
            </td>
            <td style={{ width: '100px' }}>
              <label htmlFor="writer">작성일 :</label>
            </td>
            <td>
              <input
                type="text"
                id="date"
                className="form-control"
                style={{ width: '150px', textAlign: 'center' }}
                value={new Date().toLocaleDateString('en-CA')}
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>제목</td>
            <td colSpan="3">
              <input
                type="text"
                className="form-control"
                style={{ width: '500px' }}
                placeholder="제목을 입력해 주세요"
                id="title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>내용</td>
            <td colSpan="3">
              <textarea
                type="text"
                className="form-control"
                style={{ width: '700px', height: '200px' }}
                placeholder="내용을 입력해 주세요"
                id="contents"
                onChange={(e) => setContents(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>첨부</td>
            <td colSpan="3">
              {/* <input
                type="file"
                className="form-control"
                style={{ width: '500px' }}
                id="file"
                onChange={(e) => setFile(e.target.files[0])}
              /> */}
              <FileUploader
                onSelectFile={onSelectFileHandler}
                onDeleteFile={onDeleteFileHandler}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  width: '100px',
                  marginLeft: '75px',
                  marginTop: '50px',
                  height: '35px',
                }}
                onClick={handleUpload}
              >
                저장
              </button>
              <Link
                to="/board"
                className="btn btn-success"
                style={{
                  width: '100px',
                  marginLeft: '400px',
                  marginTop: '50px',
                  height: '35px',
                }}
              >
                목록
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default BoardNew;
