import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { FiAlignRight, FiXCircle, FiChevronDown } from 'react-icons/fi';
// import { FaCalendarAlt, FaDoorOpen, FaUsers } from 'react-icons/fa';
import useAuth from './useAuth';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Navbarmenu = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const logout = async () => {
    setAuth({});
    sessionStorage.removeItem('usercheck');
    navigate('/');
  };

  useEffect(() => {
    if (sessionStorage.getItem('usercheck') === null) {
      setAuth({});
    } else {
      let username = JSON.parse(sessionStorage.getItem('usercheck')).username;
      let roles = JSON.parse(sessionStorage.getItem('usercheck')).roles;
      let logged = JSON.parse(sessionStorage.getItem('usercheck')).logged;
      setAuth({ username, roles, logged });
    }
  }, [setAuth]);

  return (
    <Navbar className="bg-body-tertiary">
      <Container>
        {/* <div className="container"> */}
        {/* <Navbar.Brand> */}
        <Link
          to="/"
          style={{
            fontSize: '25px',
            fontWeight: 600,
            textDecoration: 'none',
          }}
        >
          Logo
        </Link>
        {/* </Navbar.Brand> */}
        {/* <Link to="/home">Navbar with text</Link> */}
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {auth.logged && auth.roles ? (
            <NavDropdown
              title="Status"
              id="basic-nav-dropdown"
              style={{ fontSize: '18px', marginRight: '25px' }}
            >
              <NavDropdown.Item
                as={Link}
                to="/dashboard"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                Dashboard
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to="/dashboard"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                Dashboard2
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <></>
          )}
          {auth.logged && auth.roles ? (
            <Link
              to="/board"
              style={{
                fontSize: '18px',
                color: 'black',
                textDecoration: 'none',
                marginRight: '25px',
              }}
            >
              게시판
            </Link>
          ) : (
            <></>
          )}
          {auth.logged && auth.roles ? (
            <NavDropdown
              title="소방관리"
              id="basic-nav-dropdown"
              style={{ fontSize: '18px', marginRight: '25px' }}
            >
              <NavDropdown.Item
                as={Link}
                to="/bldregister"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                대상처 등록
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/building"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                대상처 목록
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                // to="/haeji"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                해지 대상처
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to="/bldtestplan"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                점검일정
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/bldreport"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                보고서일정
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to="/holidays"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                공휴일 입력/조회
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <></>
          )}
          {auth.logged && auth.roles ? (
            <NavDropdown
              title="회계"
              id="basic-nav-dropdown"
              style={{ fontSize: '18px', marginRight: '25px' }}
            >
              <NavDropdown.Item
                as={Link}
                to="/monthly"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                월별 할일
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to="/employee"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                {/* <Link
                to="/employee"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              > */}
                직원 현황
                {/* </Link> */}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/vehicle"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                {/* <Link
                  to="/vehicle"
                  style={{
                    fontSize: '15px',
                    color: 'black',
                    textDecoration: 'none',
                  }}
                > */}
                차량 현황
                {/* </Link> */}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/vacation"
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                근태 현황
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <></>
          )}
          {!auth.logged ? (
            <Link
              to="/login"
              style={{
                fontSize: '18px',
                color: 'black',
                textDecoration: 'none',
              }}
            >
              Login
            </Link>
          ) : (
            <Link
              to="/"
              onClick={logout}
              style={{
                fontSize: '18px',
                color: 'black',
                textDecoration: 'none',
              }}
            >
              Logout
            </Link>
          )}
        </Navbar.Collapse>
      </Container>
      {/* </div> */}
    </Navbar>
  );
};
export default Navbarmenu;
