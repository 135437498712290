// import { Button, IconButton } from 'react-icons';
// import Button from 'react-bootstrap/Button';
import React, { useRef, useState } from 'react';
import { MdAttachment } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import './FileUploader.css';

function FileUploader(props) {
  // const { accept, onSelectFile, onDeleteFile, disabled } = props;
  const { onSelectFile, onDeleteFile, disabled } = props;
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null);
  // const [files, setFiles] = useState([]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    // setFile(event.target.files[0]);
    setFile(event.target.files);
    onSelectFile(event);
  };

  const onDeleteFileHandler = () => {
    setFile(null);
    // setFiles([]);
    hiddenFileInput.current.value = null;
    onDeleteFile();
  };

  return (
    <div
      className="d-flex justify-content-between"
      style={{
        width: '700px',
        height: '35px',
        border: '1px solid #ced4da',
        borderRadius: '3px',
      }}
    >
      {/* <div className={`file-div ${disabled && 'disabled'}`}> */}
      <div className="d-flex">
        <MdAttachment
          style={{ width: '50px', marginTop: '7px', fontSize: '20px' }}
          onClick={handleClick}
          // disabled={disabled}
        />
        <input
          type="file"
          id="board"
          name="board"
          // accept={accept}
          ref={hiddenFileInput}
          onChange={handleChange}
          hidden
          // disabled={disabled}
          data-testid="file-upload-input"
          // style={{ width: '400px' }}
          multiple
        />
        <div className="file-name" style={{ marginTop: '5px' }}>
          {file ? (
            file[1] ? (
              file[2] ? (
                <div>
                  {/* {file[0].name + ' ' + file[1].name + ' ' + file[2].name} */}
                  {file[0].name + ' 외 2개'}
                </div>
              ) : (
                <div>{file[0].name + ' 외 1개'}</div>
              )
            ) : (
              <div>{file[0].name}</div>
            )
          ) : (
            <div>파일 선택 (최대 3개 선택)</div>
          )}
        </div>
      </div>
      <div style={{ width: '30px' }}>
        <MdDelete
          aria-label="delete"
          // disabled={disabled}
          color="primary"
          onClick={onDeleteFileHandler}
        />
      </div>
    </div>
  );
}

export default FileUploader;
