import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MonthlyTable(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [list, setList] = useState({
    ID: '',
    TMonth: '',
    Title: '',
    Contents: '',
  });

  const handleClose = () => {
    setShow(false);
    setList({
      ID: '',
      TMonth: '',
      Title: '',
      Contents: '',
    });
    // navigate('/todomonth');
    // window.location.reload();
  };

  function handleShow(info) {
    setShow(true);
    // console.log(info);
    handleClick(info);
    // useEffect(() => {
    //     axios.get('http://localhost:8081/bld-info-page2/'+info)
    //     .then(res => setBldList(res.data))
    //     .catch(err => console.log(err));
    //   }, [])
  }

  const handleClick = async (info) => {
    // info.preventDefault();
    // axios.get('http://localhost:8081/bld-info-page2/'+info)
    axios
      .get('http://221.143.168.230:3500/monthly-info/' + info)
      .then((res) => setList(res.data[0]))
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    // console.log(e.target.value);
    setList((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `http://221.143.168.230:3500/monthly-update/${e.currentTarget.id}`,
        list
      );
      handleClose();
      props.stateRefresh();
      navigate('/monthlylist');
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (window.confirm('해당 글을 삭제하겠습니까?')) {
      try {
        // await axios.put(`http://localhost:8081/todolistupdate/${id}`, user);
        await axios.delete(
          `http://221.143.168.230:3500/monthly-delete/${e.currentTarget.id}`
        );
        handleClose();
        props.stateRefresh();
        // window.location.reload();
        // navigate('/todomonth');
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="container">
      <table className="table mx-auto">
        {/* <table className="container"> */}
        <thead className="thead-light">
          <tr>
            {/* <th style={{ width: '100px', textAlign: 'center' }}>ID</th> */}
            <th style={{ width: '100px', textAlign: 'center' }}>월</th>
            <th style={{ width: '400px', textAlign: 'center' }}>제목</th>
            <th style={{ width: '700px', textAlign: 'center' }}>내용</th>
          </tr>
        </thead>
        <tbody>
          {props.monthlyList.map((m, index) => (
            <tr key={index}>
              {/* <td style={{width:'100px', textAlign:'center'}}>{(props.page-1)*10 + index + 1}</td> */}
              {/* <td style={{ width: '100px', textAlign: 'center' }}>{item.ID}</td> */}
              <td style={{ width: '100px', textAlign: 'center' }}>
                {m.TMonth + '월'}
              </td>
              <td
                style={{ width: '400px', textAlign: 'left', cursor: 'pointer' }}
                onClick={() => {
                  handleShow(m.ID);
                }}
              >
                {m.Title}
              </td>
              <td style={{ width: '700px', textAlign: 'left' }}>
                {m.Contents}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        size="lg" //"sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>월별 할일 수정</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-0">
            {/* <div className='h5 font-weight-bold'>월별 할일 작성</div> */}
            <form style={{ marginTop: '20px' }}>
              <div className="d-flex mt-3">
                <label htmlFor="ID" style={{ width: '90px' }}>
                  ID :
                </label>
                <input
                  type="text"
                  className="form-control"
                  style={{ width: '110px' }}
                  id="ID"
                  value={list.ID}
                  disabled
                />
              </div>
              <div className="d-flex mt-3">
                <label htmlFor="TMonth" style={{ width: '90px' }}>
                  업무 월 :
                </label>
                <input
                  type="number"
                  className="form-control"
                  min="1"
                  max="12"
                  style={{ width: '110px' }}
                  id="TMonth"
                  name="TMonth"
                  defaultValue={list.TMonth}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex mt-3">
                <label htmlFor="Title" style={{ width: '90px' }}>
                  제목 :
                </label>
                <input
                  type="text"
                  className="form-control"
                  style={{ width: '800px' }}
                  id="Title"
                  name="Title"
                  defaultValue={list.Title}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex mt-3">
                <label htmlFor="Contents" style={{ width: '90px' }}>
                  내용 :
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  style={{ width: '800px', height: '200px' }}
                  id="Contents"
                  name="Contents"
                  defaultValue={list.Contents}
                  onChange={handleChange}
                />
              </div>
              {/* <button type='submit' className='btn btn-primary' style={{width: '100px', marginLeft: '75px', marginTop: '20px', height: '35px'}} >저장</button>
                    <Link to="/todomonth" className='btn btn-success' style={{width: '100px', marginLeft: '600px', marginTop: '20px', height: '35px'}}>목록</Link> */}
            </form>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button className='mr-5' variant="primary" onClick={handleClose}>수정</Button> */}
          <div className="d-inline">
            <button
              type="submit"
              className="btn btn-danger"
              style={{ marginRight: '550px' }}
              id={list.ID}
              onClick={handleDelete}
            >
              삭제
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              id={list.ID}
              onClick={handleUpdate}
            >
              저장
            </button>
            <Button
              style={{ marginLeft: '20px' }}
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MonthlyTable;
