import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import Fullcalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../utils/Fullcalendar.css';

function Dayoff() {
  const [vacations, setVacations] = useState([]);
  const [eventClick, setEventClick] = useState(false);

  const [show, setShow] = useState(false);

  const [newEvent, setNewEvent] = useState({
    id: '',
    user: '',
    category: '',
    fromdate: '',
    todate: '',
  });

  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/vacation')
      .then((res) => {
        setVacations(res.data);
      })
      .catch((err) => console.log(err));
  }, [setVacations]);

  let data = [];

  vacations.map((h) =>
    data.push({
      id: h.ID,
      title: '[' + h.User + '] ' + h.Category,
      start: h.FromDate,
      end: moment(h.ToDate).add(1, 'd').toDate(),
      // end:
      //   h.Category === '연차'
      //     ? moment(h.ToDate).add(1, 'd').toDate()
      //     : h.ToDate,
      allDay: true,
      color:
        h.Category === '연차'
          ? 'yellowgreen'
          : h.Category === '출장'
          ? 'yellow'
          : 'gold',
    })
  );

  const handleClose = () => {
    setEventClick(false);
    setShow(false);
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    if (window.confirm('선택한 공휴일을 달력에서 삭제하겠습니까?')) {
      try {
        await axios.delete(`http://221.143.168.230:3500/vacation/${newEvent.id}`);
      } catch (err) {
        console.log(err);
      }

      // calendar에 공휴일 정보를 update 하기
      axios
        .get('http://221.143.168.230:3500/vacation')
        .then((res) => setVacations(res.data))
        .catch((err) => console.log(err));
    }

    setEventClick(false);
    setShow(false);
  };

  const handleEventClick = (info) => {
    setEventClick(true);
    axios
      .get('http://221.143.168.230:3500/vacation/' + info.event.id)
      .then((res) => {
        setNewEvent({
          id: res.data[0].ID,
          user: res.data[0].User,
          category: res.data[0].Category,
          fromdate: moment(res.data[0].FromDate).format('YYYY-MM-DD'),
          todate: moment(res.data[0].ToDate).format('YYYY-MM-DD'),
        });
      })
      .catch((err) => console.log(err));

    setShow(true);
  };

  const handleDateClick = (info) => {
    setNewEvent({
      id: '',
      user: '',
      category: '',
      fromdate: info.dateStr,
      todate: info.dateStr,
    });

    setShow(true);
  };

  const handleEditVacation = (e) => {
    setNewEvent((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      await axios.put(
        `http://221.143.168.230:3500/vacation/${newEvent.id}`,
        newEvent
      );
    } catch (err) {
      console.log(err);
    }

    // calendar에 정보 뿌리기 위해 data 불러오기
    axios
      .get('http://221.143.168.230:3500/vacation')
      .then((res) => setVacations(res.data))
      .catch((err) => console.log(err));

    setShow(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // vacation에 근태 저장
    try {
      await axios.post('http://221.143.168.230:3500/vacation', newEvent);
    } catch (err) {
      console.log(err);
    }

    // calendar에 정보 뿌리기 위해 data 불러오기
    axios
      .get('http://221.143.168.230:3500/vacation')
      .then((res) => setVacations(res.data))
      .catch((err) => console.log(err));
    // external을 update 하기 (2024년7월 입력된 대상처 제외)

    setShow(false);
  };

  return (
    <div className="d-flex mt-4">
      <div className="mx-auto" style={{ width: '85%' }}>
        <Fullcalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            interactionPlugin,
          ]}
          //   themeSystem="bootstrap"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
          }}
          titleFormat={function (date) {
            return (
              date.date.year + '년 ' + (date.date.month + 1) + '월 (근태 현황)'
            );
          }}
          eventStartEditable={true} // editable은 두개 모드 가능 (eventStartEditable and eventDurationEditable)
          selectable={true}
          events={data}
          eventClick={handleEventClick} //이벤트 클릭
          dateClick={handleDateClick} //data 입력을 위한 날짜 클릭
          //   droppable={true}  // external drop 을 위해
        />
      </div>
      <Modal
        size="lg" //"sm lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>근태 입력/수정</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td style={{ width: '150px' }}>이름</td>
                  <td>
                    {eventClick ? (
                      <input
                        type="text"
                        name="user"
                        className="form-control"
                        style={{ width: '40%' }}
                        value={newEvent.user}
                        onChange={handleEditVacation}
                      />
                    ) : (
                      <input
                        type="text"
                        name="user"
                        className="form-control"
                        style={{ width: '40%' }}
                        onChange={handleEditVacation}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>근태 종류</td>
                  <td>
                    <select
                      id="category"
                      name="category"
                      value={newEvent.category}
                      className="form-select"
                      style={{ width: '40%' }}
                      onChange={handleEditVacation}
                    >
                      <option value="선택">선택</option>
                      <option value="연차">연차</option>
                      <option value="출장">출장</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>기간</td>
                  <td>
                    <div className="d-flex">
                      <input
                        type="date"
                        name="fromdate"
                        className="form-control"
                        style={{ width: '30%' }}
                        defaultValue={newEvent.fromdate}
                        disabled
                      />
                      {'\u00A0'} ~ {'\u00A0'}
                      <input
                        type="date"
                        name="todate"
                        className="form-control"
                        style={{ width: '30%' }}
                        defaultValue={newEvent.todate}
                        onChange={handleEditVacation}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td>
                  {eventClick ? (
                    <button
                      type="submit"
                      className="btn btn-warning"
                      // style={{ marginRight: '550px' }}
                      id={newEvent.id}
                      onClick={handleDelete}
                    >
                      삭제
                    </button>
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  {eventClick ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id={newEvent.id}
                      onClick={handleUpdate}
                    >
                      저장
                    </button>
                  ) : newEvent.user !== '' && newEvent.category !== '' ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id={newEvent.id}
                      onClick={handleSave}
                    >
                      저장
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled>
                      저장
                    </button>
                  )}
                </td>

                <td>
                  <Button variant="secondary" onClick={handleClose}>
                    취소
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
          {/* <div className="d-flex flex-row">
            {calendarClick ? (
              <Button
                variant="secondary"
                style={{ justifyItems: 'flex-start' }}
                onClick={handleDelete}
              >
                삭제
              </Button>
            ) : (
              <></>
            )}
            <Button variant="secondary" onClick={handleClose}>
              취소
            </Button>
            <Button variant="primary" onClick={saveEvent}>
              저장
            </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Dayoff;
