import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../Users/useAuth';
import moment from 'moment';

function BuildingInfo() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  const [building, setBuilding] = useState({
    DocuNum: '',
    Service: '',
    Damdang: '',
    BLD_Name: '',
    Zip: '',
    Address1: '',
    Address2: '',
    ADate: '',
    PDate: '',
    PlanDate: '',
    Floor: '',
    Basement: '',
    Dong: '',
    Area1: '',
    Area2: '',
    Area3: '',
    Geub: '',
    Yongdo: '',
    Sede: '',
    Dajung: '',
    BFOS: '',
    Jakdong: '',
    Jonghap: '',
    Manager1: '',
    Phone1: '',
    Email1: '',
    Manager2: '',
    Phone2: '',
    Email2: '',
    SafetyMain: '',
    SafetyType: '',
    SafetySub: '',
    Remark: '',
    Daejang: '',
    isDeleted: '',
  });

  const [contract, setContract] = useState({
    DocuNum: '',
    Owner1: '',
    OwnerPhone1: '',
    OwnerEmail1: '',
    Owner2: '',
    OwnerPhone2: '',
    OwnerEmail2: '',
    Comment1: '',
    Comment2: '',
    Contract: '',
  });

  const [facility, setFacility] = useState({
    DocuNum: '',
    SuJangso: '',
    Susingi1: '',
    Susingi2: '',
    Susingi3: '',
    PuJangso: '',
    Pump1: '',
    Pump2: '',
    Pump3: '',
  });

  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/building/info1/' + id)
      .then((res) => setBuilding(res.data[0]))
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/building/info2/' + id)
      .then((res) => setContract(res.data[0]))
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/building/info3/' + id)
      .then((res) => setFacility(res.data[0]))
      .catch((err) => console.log(err));
  }, [id]);

  const downloadPdf = async (filename) => {
    await axios
      .get(`http://221.143.168.230:3500/building/download?q=${filename}`, {
        responseType: 'blob', // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    if (window.confirm('대상처를 해지 처리 하겠습니까?')) {
      try {
        await axios.delete(
          `http://221.143.168.230:3500/building/delete/${e.currentTarget.id}`
        );
        navigate('/building');
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="mx-auto" style={{ width: '85%' }}>
      <div className="mt-5 shadow p-3 bg-white rounded">
        <div>
          <div className="d-flex flex-row">
            <span style={{ fontSize: '20px', fontWeight: 600 }}>
              1. 기본정보
            </span>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td
                    className="table-secondary"
                    style={{ width: '12%', paddingTop: '15px' }}
                  >
                    관리번호
                  </td>
                  <td style={{ width: '24%', paddingTop: '15px' }}>
                    <span>
                      지안-
                      {building.DocuNum.substring(
                        3,
                        building.DocuNum.length
                      ).padStart(5, '0')}
                    </span>
                  </td>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    업무구분
                  </td>
                  <td style={{ width: '22%' }}>
                    <span
                      style={{
                        fontSize: '14px',
                        color: 'white',
                        background:
                          building.Service === 1 ? '#81b622' : '#5A4FCF',
                        borderRadius: '3px',
                        border:
                          building.Service === 1
                            ? '1px solid #81b622'
                            : '1px solid #5A4FCF',
                      }}
                    >
                      {building.Service === 1 ? '월' : '자'}
                    </span>
                  </td>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    업무담당자
                  </td>
                  <td style={{ width: '18%' }}>
                    {building.Damdang ? (
                      <span>{building.Damdang}</span>
                    ) : (
                      <>-</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">건물명</td>
                  <td>
                    <span>{building.BLD_Name}</span>
                  </td>
                  <td className="table-secondary">주소</td>
                  <td colSpan="3">
                    <div className="d-flex">
                      <span>
                        [우편번호 : {building.Zip}] {'\u00A0'}
                        {'\u00A0'}
                      </span>
                      <span>
                        {building.Address1}
                        {'\u00A0'}
                      </span>
                      <span>{building.Address2}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">사용승인일</td>
                  <td>
                    {building.ADate ? (
                      <span>{moment(building.ADate).format('YYYY-MM-DD')}</span>
                    ) : (
                      <>자료없음</>
                    )}
                  </td>
                  <td className="table-secondary">건축허가일</td>
                  <td>
                    {building.PDate ? (
                      <span>{moment(building.PDate).format('YYYY-MM-DD')}</span>
                    ) : (
                      <>자료없음</>
                    )}
                  </td>
                  <td className="table-secondary">소방계획서</td>
                  <td>
                    {building.PlanDate ? (
                      <span>
                        {moment(building.PlanDate).format('YYYY-MM-DD')}
                      </span>
                    ) : (
                      <>미제출</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">층수/동수</td>
                  <td>
                    <div className="d-flex">
                      <span>
                        지상{'\u00A0'}
                        {building.Floor}층{'\u00A0'}/{'\u00A0'}
                      </span>
                      <span>
                        지하{'\u00A0'}
                        {building.Basement}층{'\u00A0'}/{'\u00A0'}
                      </span>
                      <span>{building.Dong}동</span>
                    </div>
                  </td>
                  <td className="table-secondary">연면적</td>
                  <td colSpan="3">
                    <div className="d-flex">
                      <span>
                        {building.Area1}
                        {'\u00A0'}m²
                      </span>

                      {building.Dong > 1 ? (
                        <span>
                          {'\u00A0'}/{'\u00A0'}
                          {building.Area2}
                          {'\u00A0'}m²
                        </span>
                      ) : (
                        <></>
                      )}
                      {building.Dong > 2 ? (
                        <span>
                          {'\u00A0'}/{'\u00A0'}
                          {building.Area3}
                          {'\u00A0'}m²
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">용도</td>
                  <td colSpan="3">
                    {building.Yongdo ? <span>{building.Yongdo}</span> : <>-</>}
                  </td>
                  <td className="table-secondary">세대수</td>
                  <td>
                    {building.Sede ? (
                      <span>
                        {building.Sede}
                        {'\u00A0'}세대
                      </span>
                    ) : (
                      <>-</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">다중이용업소</td>
                  <td colSpan="3">
                    {building.Dajung ? <span>{building.Dajung}</span> : <>-</>}
                  </td>
                  <td className="table-secondary">건물관리업체</td>
                  <td>
                    {building.BFOS ? <span>{building.BFOS}</span> : <>-</>}
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">작동점검</td>
                  <td>
                    {building.Jakdong ? (
                      <span>{building.Jakdong}월</span>
                    ) : (
                      <>없음</>
                    )}
                  </td>
                  <td className="table-secondary">종합점검</td>
                  <td>
                    {building.Jonghap ? (
                      <span>{building.Jonghap}월</span>
                    ) : (
                      <>없음</>
                    )}
                  </td>
                  <td className="table-secondary">건물등급</td>
                  <td>
                    {building.Geub ? <span>{building.Geub}</span> : <>-</>}
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">관리인 #1</td>
                  <td>
                    {building.Manager1 ? (
                      <span>{building.Manager1}</span>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="table-secondary">연락처 #1</td>
                  <td>
                    {building.Phone1 ? <span>{building.Phone1}</span> : <>-</>}
                  </td>
                  <td className="table-secondary">이메일 #1</td>
                  <td>
                    {building.Email1 ? <span>{building.Email1}</span> : <>-</>}
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">관리인 #2</td>
                  <td>
                    {building.Manager2 ? (
                      <span>{building.Manager2}</span>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="table-secondary">연락처 #2</td>
                  <td>
                    {building.Phone2 ? <span>{building.Phone2}</span> : <>-</>}
                  </td>
                  <td className="table-secondary">이메일 #2</td>
                  <td>
                    {building.Email2 ? <span>{building.Email2}</span> : <>-</>}
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">주 소방안전 관리자</td>
                  <td colSpan="3">
                    {building.SafetyMain ? (
                      <span>{building.SafetyMain}</span>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="table-secondary">구분</td>
                  <td>
                    {building.SafetyType ? (
                      <span>{building.SafetyType}</span>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">보조 소방 관리자</td>
                  <td colSpan="5">
                    {building.SafetySub ? (
                      <span>{building.SafetySub}</span>
                    ) : (
                      <>-</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">특기 사항</td>
                  <td colSpan="5">
                    <span style={{ whiteSpace: 'pre-line' }}>
                      {building.Remark}
                    </span>
                    {/* {building.Remark ? (
                      <textarea
                        id="remark"
                        value={building.Remark}
                        className="form-control"
                        style={{ width: '100%', height: '100px' }}
                      />
                    ) : (
                      <></>
                    )} */}
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">건축물대장</td>
                  <td colSpan="4">
                    {building.Daejang ? (
                      <span>
                        {building.Daejang.substring(
                          14,
                          building.Daejang.length
                        )}
                      </span>
                    ) : (
                      <>없음</>
                    )}
                  </td>
                  <td>
                    {building.Daejang ? (
                      <button
                        className="btn btn-success ml-3"
                        onClick={() => downloadPdf(building.Daejang)}
                      >
                        Download
                      </button>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-5 shadow p-3 bg-white rounded">
        <div className="d-flex flex-row">
          <span style={{ fontSize: '20px', fontWeight: 600 }}>
            2. 계약자 정보
          </span>
        </div>
        <div className="mx-3 mt-3">
          <table className="table table-bordered table-light">
            <tbody>
              <tr>
                <td className="table-secondary" style={{ width: '12%' }}>
                  계약자 #1
                </td>
                <td style={{ width: '23%' }}>
                  {contract.Owner1 ? <span>{contract.Owner1}</span> : <>-</>}
                </td>
                <td className="table-secondary" style={{ width: '12%' }}>
                  연락처 #1
                </td>
                <td style={{ width: '23%' }}>
                  {contract.OwnerPhone1 ? (
                    <span>{contract.OwnerPhone1}</span>
                  ) : (
                    <>-</>
                  )}
                </td>
                <td className="table-secondary" style={{ width: '12%' }}>
                  이메일 #1
                </td>
                <td style={{ width: '18%' }}>
                  {contract.OwnerEmail1 ? (
                    <span>{contract.OwnerEmail1}</span>
                  ) : (
                    <>-</>
                  )}
                </td>
              </tr>
              <tr>
                <td className="table-secondary">계약자 #2</td>
                <td>
                  {contract.Owner2 ? <span>{contract.Owner2}</span> : <>-</>}
                </td>
                <td className="table-secondary">연락처 #2</td>
                <td>
                  {contract.OwnerPhone2 ? (
                    <span>{contract.OwnerPhone2}</span>
                  ) : (
                    <>-</>
                  )}
                </td>
                <td className="table-secondary">이메일 #2</td>
                <td>
                  {contract.OwnerEmail2 ? (
                    <span>{contract.OwnerEmail2}</span>
                  ) : (
                    <>-</>
                  )}
                </td>
              </tr>
              <tr>
                <td className="table-secondary">특기사항 #1</td>
                <td colSpan="5">
                  {contract.Comment1 ? (
                    <span>{contract.Comment1}</span>
                  ) : (
                    <>-</>
                  )}
                </td>
              </tr>
              <tr>
                <td className="table-secondary">특기사항 #2</td>
                <td colSpan="5">
                  {contract.Comment2 ? (
                    <span>{contract.Comment2}</span>
                  ) : (
                    <>-</>
                  )}
                </td>
              </tr>

              <tr>
                <td className="table-secondary">계약서</td>
                <td colSpan="4">
                  {contract.Contract ? (
                    <span>
                      {contract.Contract.substring(
                        14,
                        contract.Contract.length
                      )}
                    </span>
                  ) : (
                    <>없음</>
                  )}
                </td>
                <td>
                  {contract.Contract ? (
                    <button
                      className="btn btn-success ml-3"
                      onClick={() => downloadPdf(contract.Contract)}
                    >
                      Download
                    </button>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-5 shadow p-3 bg-white rounded">
          <div style={{ fontSize: '20px', fontWeight: 600 }}>3. 건물 설비</div>
          <div className="mt-3">
            <span style={{ fontSize: '17px', fontWeight: 600 }}>
              3-1. 수신기
            </span>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    수신기 위치
                  </td>
                  <td>
                    <span style={{ whiteSpace: 'pre-line' }}>
                      {facility.SuJangso}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td style={{ width: '33%' }}>
                    {facility.Susingi1 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Susingi1}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                  <td style={{ width: '33%' }}>
                    {facility.Susingi2 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Susingi2}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                  <td style={{ width: '34%' }}>
                    {facility.Susingi3 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Susingi3}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-3">
            <span style={{ fontSize: '17px', fontWeight: 600 }}>3-2. 펌프</span>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    펌프 위치
                  </td>
                  <td>
                    <span style={{ whiteSpace: 'pre-line' }}>
                      {facility.PuJangso}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td style={{ width: '33%' }}>
                    {facility.Pump1 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Pump1}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                  <td style={{ width: '33%' }}>
                    {facility.Pump2 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Pump2}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                  <td style={{ width: '33%' }}>
                    {facility.Pump3 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Pump3}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {auth?.roles?.includes('1996') ? (
            <div
              className="d-flex flex-row justify-content-end"
              style={{ marginRight: '30px' }}
            >
              <button
                className="btn btn-danger mr-5"
                id={building.DocuNum}
                onClick={handleDelete}
              >
                해지
              </button>
              <Link
                to={`/building/edit/${building.DocuNum}`}
                className="btn btn-success"
              >
                수정
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default BuildingInfo;
