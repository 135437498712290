import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../Users/useAuth';
import moment from 'moment';
import { FaEdit } from 'react-icons/fa';

function EmployeeUpdate() {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [retire, setRetire] = useState(false);
  const [employee, setEmployee] = useState({
    Username: '',
    Pword: '',
    NewDate: '',
    ROLES: '',
  });

  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/employee/' + id)
      .then((res) => {
        // console.log(res.data[0]);
        setEmployee(res.data[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleUpdate = async () => {
    // console.log(employee.ROLES);
    try {
      await axios.put('http://221.143.168.230:3500/employee/' + id, {
        username: employee.Username,
        password: employee.Pword,
        newdate: employee.NewDate,
        roles: employee.ROLES,
        retire: retire,
      });
    } catch (err) {
      console.log(err);
    }
    setRetire(false);
    // setEmployee({
    //   Username: '',
    //   Pword: '',
    //   NewDate: '',
    //   ROLES: '',
    // });
    navigate('/employee');
  };

  return (
    <div className="container">
      <div className="h5 font-weight-bold mt-4">
        <FaEdit /> 직원 정보 수정
      </div>
      <hr className="mt-4" />
      <table className="table table-borderless mt-4">
        <tbody>
          <tr>
            <td style={{ width: '100px' }}>이름 :</td>
            <td>
              <div className="d-flex flex-row">
                <input
                  type="text"
                  id="username"
                  name="Username"
                  autoComplete="off"
                  className="form-control"
                  style={{ width: '120px', textAlign: 'center' }}
                  value={employee.Username}
                  placeholder="이름 입력"
                  onChange={(e) => {
                    setEmployee((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
                <div style={{ marginLeft: '300px' }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    // checked={serviceMonth}
                    value={retire}
                    id="retire"
                    onChange={(e) => setRetire(!retire)}
                  />
                  퇴사
                </div>
              </div>
            </td>
          </tr>
          {retire ? (
            <tr>
              <td>퇴사일</td>
              <td>
                <div className="d-flex flex-row">
                  <input
                    type="date"
                    id="lastdate"
                    name="Retire"
                    className="form-control"
                    style={{ width: '250px' }}
                    placeholder="퇴사일을 입력해 주세요"
                    defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                    onChange={(e) => {
                      setEmployee((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  />
                </div>
              </td>
            </tr>
          ) : (
            <>
              <tr>
                <td>비번</td>
                <td>
                  <input
                    type="text"
                    id="password"
                    name="Pword"
                    className="form-control"
                    style={{ width: '250px' }}
                    placeholder="비번을 입력해 주세요"
                    value={employee.Pword}
                    onChange={(e) => {
                      setEmployee((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>입사일</td>
                <td>
                  <input
                    type="date"
                    id="startdate"
                    name="NewDate"
                    className="form-control"
                    style={{ width: '250px' }}
                    placeholder="입사일을 입력해 주세요"
                    defaultValue={employee.NewDate}
                    onChange={(e) => {
                      setEmployee((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  />
                </td>
              </tr>

              {auth?.roles?.includes('1993') ? (
                <tr>
                  <td>업무 권한</td>
                  <td>
                    <select
                      id="ROLES"
                      className="form-select"
                      name="ROLES"
                      style={{ width: '150px' }}
                      onChange={(e) => {
                        setEmployee((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                    >
                      {employee.ROLES?.includes('1993') ? (
                        <>
                          <option value='["1964"]'>Normal</option>
                          <option value='["1964", "1996"]'>Editor</option>
                          <option value='["1964", "1996", "1993"]' selected>
                            Admin
                          </option>
                        </>
                      ) : employee.ROLES?.includes('1996') ? (
                        <>
                          <option value='["1964"]'>Normal</option>
                          <option value='["1964", "1996"]' selected>
                            Editor
                          </option>
                          <option value='["1964", "1996", "1993"]'>
                            Admin
                          </option>
                        </>
                      ) : (
                        <>
                          <option value='["1964"]' selected>
                            Normal
                          </option>
                          <option value='["1964", "1996"]'>Editor</option>
                          <option value='["1964", "1996", "1993"]'>
                            Admin
                          </option>
                        </>
                      )}
                    </select>
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </>
          )}
          <tr>
            <td colSpan="2">
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  width: '100px',
                  marginLeft: '75px',
                  marginTop: '50px',
                  height: '35px',
                }}
                onClick={handleUpdate}
              >
                저장
              </button>
              <Link
                to="/employee"
                className="btn btn-success"
                style={{
                  width: '100px',
                  marginLeft: '400px',
                  marginTop: '50px',
                  height: '35px',
                }}
              >
                목록
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EmployeeUpdate;
