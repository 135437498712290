import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../Users/useAuth';
import Modal from 'react-bootstrap/Modal';
import { LuAsterisk } from 'react-icons/lu';
import FileUploaderOne from '../utils/FileUploaderOne';
import FileUploaderMulti from '../utils/FileUploaderMulti';

import PopupPostCode from './PopupPostCode';

const BuildingRegister = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();

  const [countMonth, setCountMonth] = useState('');
  const [countJache, setCountJache] = useState(0);

  const [docuNum, setDocuNum] = useState('');
  const [service, setService] = useState('');
  const [damdang, setDamdang] = useState('');
  const [bldname, setBldname] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [aDate, setADate] = useState('');
  const [pDate, setPDate] = useState('');
  const [planDate, setPlanDate] = useState('');

  const [floor, setFloor] = useState(0);
  const [basement, setBasement] = useState(0);
  const [dong, setDong] = useState(1);
  const [area1, setArea1] = useState(0);
  const [area2, setArea2] = useState(0);
  const [area3, setArea3] = useState(0);
  const [geub, setGeub] = useState('');
  const [yongdo, setYongdo] = useState('');
  const [sede, setSede] = useState('');
  const [dajung, setDajung] = useState('');
  const [bfos, setBFOS] = useState('');
  const [jakdong, setJakdong] = useState(0);
  const [jonghap, setJonghap] = useState(0);

  const [manager1, setManager1] = useState('');
  const [phone1, setPhone1] = useState('');
  const [email1, setEmail1] = useState('');
  const [manager2, setManager2] = useState('');
  const [phone2, setPhone2] = useState('');
  const [email2, setEmail2] = useState('');

  const [safetyMain, setSafetyMain] = useState('');
  // const [safetyPhone, setSafetyPhone] = useState('');
  // const [safetyDate, setSafetyDate] = useState('');
  const [safetyType, setSafetyType] = useState('');
  const [safetySub, setSafetySub] = useState('');
  const [remark, setRemark] = useState('');

  const [daejang, setDaejang] = useState('');

  const [owner1, setOwner1] = useState('');
  const [ownerPhone1, setOwnerPhone1] = useState('');
  const [ownerEmail1, setOwnerEmail1] = useState('');
  const [owner2, setOwner2] = useState('');
  const [ownerPhone2, setOwnerPhone2] = useState('');
  const [ownerEmail2, setOwnerEmail2] = useState('');
  const [comment1, setComment1] = useState('');
  const [comment2, setComment2] = useState('');
  const [contract, setContract] = useState('');

  const [sujangso, setSuJangso] = useState('');
  const [susingiFile, setSusingiFile] = useState([]);
  const [pujangso, setPuJangso] = useState('');
  const [pumpFile, setPumpFile] = useState([]);

  const [boolHaeji, setBoolHaeji] = useState(false);
  const [haejiList, setHaejiList] = useState([]);
  const [haejiBuilding, setHaejiBuilding] = useState([]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    const sql = `http://221.143.168.230:3500/building/count`;
    axios
      .get(sql)
      .then((res) => {
        // setCount(res.data[0]['COUNT(*)'] + 1);
        setCountMonth(res.data[0].Service1);
        setCountJache(res.data[0].Service2);
        setDocuNum('지안-' + (res.data[0].Service1 + res.data[0].Service2 + 1));
      })
      .catch((err) => console.log(err));
  }, []);

  const handleClose = () => {
    setBoolHaeji(false);
    setShow(false);
    // navigate('/building');
  };

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const savedata = async () => {
    try {
      await axios.post('http://221.143.168.230:3500/building', {
        docuNum: docuNum,
        service: service,
        damdang: damdang,
        bldname: bldname,
        zipCode: zipCode,
        address1: address1,
        address2: address2,
        aDate: aDate,
        pDate: pDate,
        planDate: planDate,
        floor: floor,
        basement: basement,
        dong: dong,
        area1: area1,
        area2: area2,
        area3: area3,
        geub: geub,
        yongdo: yongdo,
        sede: sede,
        dajung: dajung,
        bfos: bfos,
        jakdong: jakdong,
        jonghap: jonghap,
        manager1: manager1,
        phone1: phone1,
        email1: email1,
        manager2: manager2,
        phone2: phone2,
        email2: email2,
        safetyMain: safetyMain,
        // safetyPhone: safetyPhone,
        // safetyDate: safetyDate,
        safetyType: safetyType,
        safetySub: safetySub,
        remark: remark,
        // isDeleted: isDeleted,
      });
    } catch (err) {
      console.log(err);
    }

    if (daejang) {
      const formData = new FormData();
      formData.append('daejang', daejang);
      try {
        await axios.put(
          `http://221.143.168.230:3500/building/daejang/${docuNum}`,
          formData
        );
      } catch (err) {
        console.log(err);
      }
    }

    try {
      await axios.post('http://221.143.168.230:3500/building/contract', {
        docuNum: docuNum,
        owner1: owner1,
        ownerPhone1: ownerPhone1,
        ownerEmail1: ownerEmail1,
        owner2: owner2,
        ownerPhone2: ownerPhone2,
        ownerEmail2: ownerEmail2,
        comment1: comment1,
        comment2: comment2,
      });
    } catch (err) {
      console.log(err);
    }

    if (contract) {
      const formData = new FormData();
      formData.append('contract', contract);
      try {
        await axios.put(
          `http://221.143.168.230:3500/building/contract/${docuNum}`,
          formData
        );
      } catch (err) {
        console.log(err);
      }
    }

    try {
      await axios.post('http://221.143.168.230:3500/building/facility', {
        docuNum: docuNum,
        sujangso: sujangso,
        pujangso: pujangso,
      });
    } catch (err) {
      console.log(err);
    }

    if (susingiFile) {
      const formData = new FormData();
      for (let i = 0; i < susingiFile.length; i++) {
        const item = susingiFile[i];
        formData.append('susingi', item);
      }

      try {
        await axios.put(
          `http://221.143.168.230:3500/building/susingi/${docuNum}`,
          formData
        );
      } catch (error) {
        console.log(error);
      }
    }

    if (pumpFile) {
      const formData = new FormData();
      for (let i = 0; i < pumpFile.length; i++) {
        const item = pumpFile[i];
        formData.append('pump', item);
      }

      try {
        await axios.put(
          `http://221.143.168.230:3500/building/pump/${docuNum}`,
          formData
        );
      } catch (error) {
        console.log(error);
      }
    }

    navigate('/building');
  };

  const onSelectFileHandler = (e) => {
    setDaejang(e.target.files[0]);
  };

  const onSelectFileHandler1 = (e) => {
    setContract(e.target.files[0]);
  };

  const onSelectFileSusingi = (e) => {
    setSusingiFile(e.target.files);
  };

  const onSelectFilePump = (e) => {
    setPumpFile(e.target.files);
  };

  const onDeleteFileHandler = () => {};

  const handleRadio = (e) => {
    setService(e.target.value);
  };

  const handleHaejiList = () => {
    const sql = `http://221.143.168.230:3500/building/haeji-list`;
    axios
      .get(sql)
      .then((res) => setHaejiList(res.data))
      .catch((err) => console.log(err));
    setBoolHaeji(true);
    setShow(true);
  };

  const handleRecover = async (e) => {
    console.log(haejiBuilding);

    try {
      await axios.put(
        `http://221.143.168.230:3500/building/haeji-restore/${haejiBuilding}`
      );
    } catch (error) {
      console.log(error);
    }

    setBoolHaeji(false);
    setShow(false);

    navigate('/building');
  };

  return (
    <div className="mx-auto" style={{ width: '85%' }}>
      <div className="mt-5 shadow p-3 bg-white rounded">
        <div>
          <div className="d-flex flex-row">
            <span style={{ fontSize: '20px', fontWeight: 600 }}>
              1. 기본정보
            </span>
            <span style={{ marginLeft: '80%' }}>
              <button className="btn btn-primary" onClick={handleHaejiList}>
                해지 대상처
              </button>
            </span>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td
                    className="table-secondary"
                    style={{ width: '12%', paddingTop: '15px' }}
                  >
                    관리번호
                  </td>
                  <td style={{ width: '23%', paddingTop: '15px' }}>
                    <span>
                      지안-{' '}
                      {String(countMonth + countJache + 1).padStart(5, '0')}
                    </span>
                  </td>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    업무구분
                    <LuAsterisk />
                  </td>
                  <td style={{ width: '23%' }}>
                    <div className="d-flex ml-5">
                      <input
                        type="radio"
                        id="service1"
                        value="1"
                        checked={service === '1'}
                        onChange={handleRadio}
                      />
                      <span>월관리</span>
                      <input
                        type="radio"
                        id="service2"
                        style={{ marginLeft: '50px' }}
                        value="2"
                        checked={service === '2'}
                        onChange={handleRadio}
                      />
                      <span>자체점검</span>
                    </div>
                  </td>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    업무담당자
                  </td>
                  <td style={{ width: '18%' }}>
                    <input
                      type="text"
                      id="damdang"
                      className="form-control"
                      placeholder="담당자"
                      onChange={(e) => setDamdang(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">
                    건물명
                    <LuAsterisk />
                  </td>
                  <td>
                    <input
                      type="text"
                      id="bldname"
                      className="form-control"
                      onChange={(e) => setBldname(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">
                    주소
                    <LuAsterisk />
                  </td>
                  <td colSpan="3">
                    <div className="d-flex">
                      <input
                        type="text"
                        id="zipcode"
                        value={zipCode}
                        className="form-control"
                        style={{ width: '90px' }}
                        placeholder="우편번호"
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                      <button
                        onClick={handleShow}
                        className="btn btn-outline-success"
                        style={{ width: '160px' }}
                      >
                        주소찾기
                      </button>
                      <input
                        type="text"
                        id="address1"
                        value={address1}
                        className="form-control"
                        style={{ marginLeft: '10px' }}
                        onChange={(e) => setAddress1(e.target.value)}
                      />
                      <input
                        type="text"
                        id="address2"
                        value={address2}
                        className="form-control"
                        style={{ width: '100px', marginLeft: '10px' }}
                        placeholder="상세주소"
                        onChange={(e) => setAddress2(e.target.value)}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">사용승인일</td>
                  <td>
                    <input
                      type="date"
                      id="adate"
                      className="form-control"
                      onChange={(e) => setADate(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">건축허가일</td>
                  <td>
                    <input
                      type="date"
                      id="pdate"
                      className="form-control"
                      onChange={(e) => setPDate(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">소방계획서</td>
                  <td>
                    <input
                      type="date"
                      id="plandate"
                      className="form-control"
                      onChange={(e) => setPlanDate(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">
                    층수/동수
                    <LuAsterisk />
                  </td>
                  <td>
                    <div className="d-flex">
                      <span style={{ fontSize: '13px' }}>지상{'\u00A0'}</span>
                      <input
                        type="text"
                        id="floor"
                        className="form-control"
                        style={{ width: '45px' }}
                        onChange={(e) => setFloor(e.target.value)}
                      />
                      <span style={{ fontSize: '13px' }}>
                        층, 지하{'\u00A0'}
                      </span>
                      <input
                        type="text"
                        id="basement"
                        className="form-control"
                        style={{ width: '45px' }}
                        onChange={(e) => setBasement(e.target.value)}
                      />
                      <span style={{ fontSize: '13px' }}>층,{'\u00A0'}</span>
                      <input
                        type="text"
                        id="dong"
                        className="form-control"
                        style={{ width: '45px' }}
                        onChange={(e) => setDong(e.target.value)}
                      />
                      <span style={{ fontSize: '13px' }}>개동</span>
                    </div>
                  </td>
                  <td className="table-secondary">연면적</td>
                  <td colSpan="3">
                    <div className="d-flex">
                      <input
                        type="text"
                        id="area1"
                        className="form-control"
                        style={{ width: '130px' }}
                        onChange={(e) => setArea1(e.target.value)}
                      />
                      <span>{'\u00A0'}m²</span>
                      <input
                        type="text"
                        id="area2"
                        className="form-control"
                        style={{ width: '130px', marginLeft: '30px' }}
                        onChange={(e) => setArea2(e.target.value)}
                      />
                      <span>{'\u00A0'}m²</span>
                      <input
                        type="text"
                        id="area3"
                        className="form-control"
                        style={{ width: '130px', marginLeft: '30px' }}
                        onChange={(e) => setArea3(e.target.value)}
                      />
                      <span>{'\u00A0'}m²</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">용도</td>
                  <td colSpan="3">
                    <input
                      type="text"
                      id="yongdo"
                      className="form-control"
                      onChange={(e) => setYongdo(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">세대수</td>
                  <td>
                    <div className="d-flex">
                      <input
                        type="text"
                        id="sede"
                        className="form-control"
                        style={{ width: '130px' }}
                        onChange={(e) => setSede(e.target.value)}
                      />
                      <span>{'\u00A0'}세대</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">다중이용업소</td>
                  <td colSpan="3">
                    <input
                      type="text"
                      id="dajung"
                      className="form-control"
                      onChange={(e) => setDajung(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">건물관리업체</td>
                  <td>
                    <input
                      type="text"
                      id="bfos"
                      className="form-control"
                      placeholder="건물관리 업체"
                      onChange={(e) => setBFOS(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">작동점검</td>
                  <td>
                    <select
                      id="jakdongmonth"
                      className="form-select"
                      onChange={(e) => {
                        setJakdong(e.target.value);
                      }}
                    >
                      <option value="0">선택</option>
                      <option value="1">1월</option>
                      <option value="2">2월</option>
                      <option value="3">3월</option>
                      <option value="4">4월</option>
                      <option value="5">5월</option>
                      <option value="6">6월</option>
                      <option value="7">7월</option>
                      <option value="8">8월</option>
                      <option value="9">9월</option>
                      <option value="10">10월</option>
                      <option value="11">11월</option>
                      <option value="12">12월</option>
                    </select>
                  </td>
                  <td className="table-secondary">종합점검</td>
                  <td>
                    <select
                      id="jonghapmonth"
                      className="form-select"
                      onChange={(e) => {
                        setJonghap(e.target.value);
                      }}
                    >
                      <option value="0">선택</option>
                      <option value="1">1월</option>
                      <option value="2">2월</option>
                      <option value="3">3월</option>
                      <option value="4">4월</option>
                      <option value="5">5월</option>
                      <option value="6">6월</option>
                      <option value="7">7월</option>
                      <option value="8">8월</option>
                      <option value="9">9월</option>
                      <option value="10">10월</option>
                      <option value="11">11월</option>
                      <option value="12">12월</option>
                    </select>
                  </td>
                  <td className="table-secondary">건물등급</td>
                  <td>
                    <select
                      id="geub"
                      className="form-select"
                      onChange={(e) => {
                        setGeub(e.target.value);
                      }}
                    >
                      <option value="선택">선택</option>
                      <option value="특급">특급</option>
                      <option value="1급">1급</option>
                      <option value="2급">2급</option>
                      <option value="3급">3급</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">관리인 #1</td>
                  <td>
                    <input
                      type="text"
                      id="manager1"
                      className="form-control"
                      placeholder="직위, 이름 ..."
                      onChange={(e) => setManager1(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">연락처 #1</td>
                  <td>
                    <input
                      type="text"
                      id="phone1"
                      className="form-control"
                      placeholder="연락처"
                      onChange={(e) => setPhone1(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">이메일 #1</td>
                  <td>
                    <input
                      type="text"
                      id="email1"
                      className="form-control"
                      placeholder="이메일"
                      onChange={(e) => setEmail1(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">관리인 #2</td>
                  <td>
                    <input
                      type="text"
                      id="manager2"
                      className="form-control"
                      placeholder="직위, 이름 ..."
                      onChange={(e) => setManager2(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">연락처 #2</td>
                  <td>
                    <input
                      type="text"
                      id="phone2"
                      className="form-control"
                      placeholder="연락처"
                      onChange={(e) => setPhone2(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">이메일 #2</td>
                  <td>
                    <input
                      type="text"
                      id="email2"
                      className="form-control"
                      placeholder="이메일"
                      onChange={(e) => setEmail2(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">주 소방안전 관리자</td>
                  <td colSpan="3">
                    <input
                      type="text"
                      id="safetymain"
                      className="form-control"
                      placeholder="이름, 연락처, 선임일자 ..."
                      onChange={(e) => setSafetyMain(e.target.value)}
                    />
                  </td>
                  {/* <td className="table-secondary">연락처</td>
                  <td>
                    <input
                      type="text"
                      id="safetyphone"
                      className="form-control"
                      onChange={(e) => setSafetyPhone(e.target.value)}
                    />
                  </td> */}
                  <td className="table-secondary">구분</td>
                  <td>
                    <input
                      type="text"
                      id="safetytype"
                      className="form-control"
                      onChange={(e) => setSafetyType(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">보조 소방 관리자</td>
                  <td colSpan="5">
                    <input
                      type="text"
                      id="safetysub"
                      className="form-control"
                      placeholder="이름, 연락처, 선임일자 ..."
                      onChange={(e) => setSafetySub(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">특기 사항</td>
                  <td colSpan="5">
                    <textarea
                      type="text"
                      id="remark"
                      className="form-control"
                      style={{ width: '100%', height: '100px' }}
                      placeholder="특기 사항"
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">건축물대장</td>
                  <td colSpan="5">
                    <FileUploaderOne
                      onSelectFile={onSelectFileHandler}
                      onDeleteFile={onDeleteFileHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {auth?.roles?.includes('1996') ? (
        <div className="mt-5 shadow p-3 bg-white rounded">
          <div className="d-flex flex-row">
            <span style={{ fontSize: '20px', fontWeight: 600 }}>
              2. 계약 사항
            </span>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    계약자 #1
                  </td>
                  <td style={{ width: '24%' }}>
                    <input
                      type="text"
                      id="owner1"
                      className="form-control"
                      placeholder="건물 관리 업체"
                      onChange={(e) => setOwner1(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    연락처 #1
                  </td>
                  <td style={{ width: '22%' }}>
                    <input
                      type="text"
                      id="ownerphone1"
                      className="form-control"
                      onChange={(e) => setOwnerPhone1(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    이메일 #1
                  </td>
                  <td style={{ width: '18%' }}>
                    <input
                      type="text"
                      id="ownermail1"
                      className="form-control"
                      onChange={(e) => setOwnerEmail1(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">계약자 #2</td>
                  <td>
                    <input
                      type="text"
                      id="owner2"
                      className="form-control"
                      placeholder="건물주 관련"
                      onChange={(e) => setOwner2(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">연락처 #2</td>
                  <td>
                    <input
                      type="text"
                      id="ownerphone2"
                      className="form-control"
                      onChange={(e) => setOwnerPhone2(e.target.value)}
                    />
                  </td>
                  <td className="table-secondary">이메일 #2</td>
                  <td>
                    <input
                      type="text"
                      id="ownermail2"
                      className="form-control"
                      onChange={(e) => setOwnerEmail2(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">특기사항 #1</td>
                  <td colSpan="5">
                    <textarea
                      type="text"
                      id="comment1"
                      className="form-control"
                      style={{ width: '100%', height: '100px' }}
                      placeholder="특기 사항"
                      onChange={(e) => setComment1(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">특기사항 #2</td>
                  <td colSpan="5">
                    <textarea
                      type="text"
                      id="comment2"
                      className="form-control"
                      style={{ width: '100%', height: '100px' }}
                      placeholder="특기 사항"
                      onChange={(e) => setComment2(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">계약서</td>
                  <td colSpan="5">
                    <FileUploaderOne
                      onSelectFile={onSelectFileHandler1}
                      onDeleteFile={onDeleteFileHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="mt-5 shadow p-3 bg-white rounded">
        <div style={{ fontSize: '20px', fontWeight: 600 }}>3. 건물 설비</div>
        <div className="mt-3">
          <span style={{ fontSize: '17px' }}>3-1. 수신기</span>
        </div>
        <div className="mx-3 mt-3">
          <table className="table table-bordered table-light">
            <tbody>
              <tr>
                <td className="table-secondary" style={{ width: '12%' }}>
                  수신기 위치
                </td>
                <td>
                  <textarea
                    type="text"
                    id="sujangso"
                    className="form-control"
                    style={{ width: '100%', height: '100px' }}
                    onChange={(e) => setSuJangso(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-secondary">수신기</td>
                <td colSpan="3">
                  <FileUploaderMulti
                    onSelectFile={onSelectFileSusingi}
                    onDeleteFile={onDeleteFileHandler}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <span style={{ fontSize: '17px' }}>3-2. 펌프</span>
        </div>
        <div className="mx-3 mt-3">
          <table className="table table-bordered table-light">
            <tbody>
              <tr>
                <td className="table-secondary" style={{ width: '12%' }}>
                  펌프 위치
                </td>
                <td>
                  <textarea
                    type="text"
                    id="pujangso"
                    className="form-control"
                    style={{ width: '100%', height: '100px' }}
                    onChange={(e) => setPuJangso(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-secondary" style={{ width: '12%' }}>
                  펌프
                </td>
                <td>
                  <FileUploaderMulti
                    onSelectFile={onSelectFilePump}
                    onDeleteFile={onDeleteFileHandler}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="d-flex flex-row justify-content-end"
          style={{ marginRight: '30px' }}
        >
          <button className="btn btn-primary" onClick={savedata}>
            저장
          </button>
        </div>
      </div>

      {boolHaeji ? (
        <Modal
          // size="sm" //"sm lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>해지 대상처 복구</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td style={{ width: '20%' }}>대상처</td>
                    <td colSpan="2">
                      <select
                        id="haejibuilding"
                        className="form-select"
                        onChange={(e) => setHaejiBuilding(e.target.value)}
                      >
                        <option value="해지 대상처 선택">
                          해지 대상처 선택
                        </option>
                        {haejiList.map((h) => (
                          <option key={h.DocuNum} value={h.DocuNum}>
                            {h.BLD_Name}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ marginRight: '80%' }}>
              <button className="btn btn-success" onClick={handleRecover}>
                복구
              </button>
            </div>
            <div>
              <button className="btn btn-secondary" onClick={handleClose}>
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          // size="lg" //"sm lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>주소 검색</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column" style={{ height: '400px' }}>
              <PopupPostCode
                onClose={handleClose}
                zipCode={zipCode}
                setZipCode={setZipCode}
                address1={address1}
                setAddress1={setAddress1}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default BuildingRegister;
