import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import EmployeePage from './EmployeePage';
import EmployeeTable from './EmployeeTable';
import { FcManager } from 'react-icons/fc';

function EmployeeMain() {
  const [employeeList, setEmployeeList] = useState([]); //대상처 배열
  const [searchText, setSearchText] = useState(''); //검색어
  const [page, setPage] = useState(1); //page 1 설정
  const limit = 20; //화면 최대 갯수

  function stateRefresh() {
    setPage(1);
    const sql = `http://221.143.168.230:3500/employee`;
    axios
      .get(sql)
      .then((res) => setEmployeeList(res.data))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    stateRefresh();
  }, []);

  const CurrentEmployeeList = (employeeList) => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const slicedList = employeeList.slice(startIndex, endIndex);
    return slicedList;
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    var url;
    // url = `http://221.143.168.230:3500/board-search?q=${searchText}`;
    url = `http://221.143.168.230:3500/employee?q=${searchText}`;
    await axios
      .get(url)
      .then((res) => {
        setEmployeeList(res.data);
        setSearchText('');
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="container">
      <table className="table table-borderless mt-3">
        <tbody>
          <tr>
            <td>
              <span className="h5 font-weight-bold">
                <FcManager /> 직원 현황
              </span>
            </td>
            <td style={{ width: '50%', textAlign: 'right' }}>
              <Link
                to="/employee/new"
                className="btn btn-primary"
                style={{ marginLeft: '30px', width: '100px', height: '35px' }}
              >
                직원 등록
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
      <hr className="mt-1" />
      <div className="mx-auto mt-3">
        <EmployeeTable
          employeeList={CurrentEmployeeList(employeeList)}
          page={page}
          stateRefresh={stateRefresh}
        />
        <EmployeePage
          total={employeeList.length}
          page={page}
          setPage={setPage}
          limit={limit}
          siblings={1}
        />
      </div>
    </div>
  );
}

export default EmployeeMain;
