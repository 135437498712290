import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import Fullcalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
// import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
// import ko from '@fullcalendar/core/locales/ko';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './Schedule.css';

function Holidays() {
  const today = new Date();
  const intyear = today.getFullYear();

  const [holidays, setHolidays] = useState([]);
  const [eventClick, setEventClick] = useState(false);
  const [currentYear, setCurrentYear] = useState(intyear);
  const [show, setShow] = useState(false);

  const [newEvent, setNewEvent] = useState({
    id: '',
    years: '',
    title: '',
    start: '',
    end: '',
  });

  useEffect(() => {
    axios
      .get(`http://221.143.168.230:3500/test/holidays?cy=${currentYear}`)
      .then((res) => {
        setHolidays(res.data);
      })
      .catch((err) => console.log(err));
  }, [currentYear]);

  const myholidays = holidays.map((h) => ({
    id: h.ID,
    title: h.Title,
    start: h.Start,
    end: h.End,
    allDay: true,
    color: h.Color,
    // display: 'background',
  }));

  //handle event receive
  const handleCalendarDrop = async (e) => {
    // e.preventDefault();  여기서 사용하면 에러 발생!!!
    console.log(e.oldEvent.start);
    const afterDrop = {
      // title는 안 변하므로
      start: moment(e.event.start).format('YYYY-MM-DD'),
      end: moment(e.event.start).format('YYYY-MM-DD'),
    };

    try {
      await axios.put(
        `http://221.143.168.230:3500/holidays-drop/${e.event.id}`,
        afterDrop
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setEventClick(false);
    setShow(false);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    console.log(newEvent.id);
    if (window.confirm('선택한 공휴일을 달력에서 삭제하겠습니까?')) {
      try {
        await axios.delete(
          `http://221.143.168.230:3500/test/holidays-delete/${newEvent.id}`
        );
      } catch (err) {
        console.log(err);
      }

      // calendar에 공휴일 정보를 update 하기
      axios
        .get('http://221.143.168.230:3500/test/holidays')
        .then((res) => setHolidays(res.data))
        .catch((err) => console.log(err));
    }

    setEventClick(false);
    setShow(false);
  };

  const handleEventClick = (info) => {
    setEventClick(true);

    setNewEvent({
      id: info.event.id,
      title: info.event.title,
      start: moment(info.event.start).format('YYYY-MM-DD'),
      end: moment(info.event.end).format('YYYY-MM-DD'),
    });

    setShow(true);
  };

  const handleDateClick = (info) => {
    // console.log(info, moment(info.dateStr).format('YYYY-MM-DD'));
    console.log(info.date.getFullYear());
    setNewEvent({
      id: '',
      years: info.date.getFullYear(),
      title: '',
      start: info.dateStr,
      end: info.dateStr,
      // start: moment(info.dateStr).format('YYYY-MM-DD'),
      // end: moment(info.dateStr).format('YYYY-MM-DD'),
    });

    setShow(true);
  };

  const handleTitleChange = (e) => {
    setNewEvent((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    // setShow(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // testschedule에 external 정보 저장
    try {
      await axios.put(
        `http://221.143.168.230:3500/test/holidays-update/${newEvent.id}`,
        newEvent
      );
    } catch (err) {
      console.log(err);
    }

    // calendar에 정보 뿌리기 위해 data 불러오기
    axios
      .get(`http://221.143.168.230:3500/test/holidays?cy=${currentYear}`)
      .then((res) => setHolidays(res.data))
      .catch((err) => console.log(err));

    setShow(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // console.log(newEvent.title, newEvent.start, newEvent.end);
    // holidays에 공휴일 저장
    try {
      await axios.post('http://221.143.168.230:3500/test/holidays-save', newEvent);
    } catch (err) {
      console.log(err);
    }

    // calendar에 정보 뿌리기 위해 data 불러오기
    axios
      .get(`http://221.143.168.230:3500/test/holidays?cy=${currentYear}`)
      .then((res) => setHolidays(res.data))
      .catch((err) => console.log(err));
    // external을 update 하기 (2024년7월 입력된 대상처 제외)

    setShow(false);
  };

  return (
    <div className="d-flex">
      <div
        className="d-flex flex-column mx-4 mt-3"
        style={{
          width: '15%',
          margin: '6px 0',
          padding: '0 10px',
          border: '1px solid #ccc',
          background: '#eee',
        }}
      >
        <span
          style={{
            margin: '10px 0',
            fontSize: '18px',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          공휴일 ({currentYear}년)
        </span>
        <div>
          {myholidays.map((m) => (
            <div>{m.title}</div>
          ))}
        </div>
      </div>
      <div className="mt-4 mr-4" style={{ width: '85%' }}>
        <Fullcalendar
          plugins={[
            dayGridPlugin, // timeGridPlugin,
            listPlugin,
            interactionPlugin,
          ]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,listMonth', //,timeGridWeek,timeGridDay
          }}
          eventStartEditable={true} // editable은 두개 모드 가능 (eventStartEditable and eventDurationEditable)
          selectable={true}
          events={myholidays}
          eventDrop={handleCalendarDrop}
          eventClick={handleEventClick} //이벤트 클릭
          dateClick={handleDateClick} //data 입력을 위한 날짜 클릭
          contentHeight={600}
          datesSet={(e) => {
            let intyear = e.view.currentStart.getFullYear();
            setCurrentYear(intyear);
          }}
          //   droppable={true}  // external drop 을 위해
        />
      </div>
      <Modal
        size="lg" //"sm lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>공휴일 입력/수정/삭제</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td style={{ width: '150px' }}>공휴일</td>
                  <td>
                    {eventClick ? (
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        style={{ width: '40%' }}
                        value={newEvent.title}
                        onChange={handleTitleChange}
                      />
                    ) : (
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        style={{ width: '40%' }}
                        //   value={newEvent.title}
                        onChange={handleTitleChange}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>날짜</td>
                  <td>
                    <input
                      type="date"
                      name="start"
                      className="form-control"
                      style={{ width: '40%' }}
                      value={newEvent.start}
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td>
                  {eventClick ? (
                    <button
                      type="submit"
                      className="btn btn-warning"
                      // style={{ marginRight: '550px' }}
                      id={newEvent.id}
                      onClick={handleDelete}
                    >
                      삭제
                    </button>
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  {eventClick ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id={newEvent.id}
                      onClick={handleUpdate}
                    >
                      수정
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id={newEvent.id}
                      onClick={handleSave}
                    >
                      저장
                    </button>
                  )}
                </td>
                <td>
                  <Button variant="secondary" onClick={handleClose}>
                    취소
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
          {/* <div className="d-flex flex-row">
            {calendarClick ? (
              <Button
                variant="secondary"
                style={{ justifyItems: 'flex-start' }}
                onClick={handleDelete}
              >
                삭제
              </Button>
            ) : (
              <></>
            )}
            <Button variant="secondary" onClick={handleClose}>
              취소
            </Button>
            <Button variant="primary" onClick={saveEvent}>
              저장
            </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Holidays;
