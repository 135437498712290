import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import PaginationBLD from './PaginationBLD';
// import Select from 'react-select';
import MonthlyTable from './MonthlyTable';
import MonthlyPage from './MonthlyPage';
// import './BuildingMain.css';

function MonthlyMain() {
  // const [show, setShow] = useState(true);

  const [selected, setSelected] = useState('');
  const [searchText, setSearchText] = useState('');
  const [monthlyList, setMonthlyList] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 10;

  // const options = [
  //   { value: 0, label: '전체월' },
  //   { value: 1, label: '1월' },
  //   { value: 2, label: '2월' },
  //   { value: 3, label: '3월' },
  //   { value: 4, label: '4월' },
  //   { value: 5, label: '5월' },
  //   { value: 6, label: '6월' },
  //   { value: 7, label: '7월' },
  //   { value: 8, label: '8월' },
  //   { value: 9, label: '9월' },
  //   { value: 10, label: '10월' },
  //   { value: 11, label: '11월' },
  //   { value: 12, label: '12월' },
  // ];

  function stateRefresh() {
    // setPage(1);
    // alert(page);
    const sql = `http://221.143.168.230:3500/monthly`;
    axios
      .get(sql)
      .then((res) => setMonthlyList(res.data))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (selected) {
      // setPage(1)
      var url;
      url = `http://221.143.168.230:3500/monthcheck?search=${selected}`;
      axios
        .get(url)
        .then((res) => setMonthlyList(res.data))
        .catch((err) => console.log(err));
    } else {
      stateRefresh();
      // axios
      //   .get(`http://221.143.168.230:3500/monthly`)
      //   .then((res) => setMonthlyList(res.data))
      //   .catch((err) => console.log(err));
    }
  }, [selected]);

  const CurrentMonthList = (monthlyList) => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const slicedList = monthlyList.slice(startIndex, endIndex);
    return slicedList;
  };

  const handleSearch = async (e) => {
    var url;
    e.preventDefault();
    url = `http://221.143.168.230:3500/monthly/search?q=${searchText}`;
    await axios
      .get(url)
      .then((res) => {
        setMonthlyList(res.data);
        setSearchText('');
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  return (
    <div className="container">
      <div className="d-flex mt-4">
        <span
          style={{
            width: '100px',
            marginTop: '10px',
            // marginLeft: '40px',
            fontSize: '15px',
            fontWeight: '600',
          }}
        >
          월별 할일 :
        </span>
        <span style={{ marginLeft: '15px', width: '120PX' }}>
          <select id="month" className="form-select" onChange={handleChange}>
            <option value="0">월 선택</option>
            <option value="0">전체</option>
            <option value="1">1월</option>
            <option value="2">2월</option>
            <option value="3">3월</option>
            <option value="4">4월</option>
            <option value="5">5월</option>
            <option value="6">6월</option>
            <option value="7">7월</option>
            <option value="8">8월</option>
            <option value="9">9월</option>
            <option value="10">10월</option>
            <option value="11">11월</option>
            <option value="12">12월</option>
          </select>
        </span>
        <form onSubmit={handleSearch} className="d-inline-flex">
          <input
            type="text"
            id="search"
            className="form-control"
            style={{ marginLeft: '600px', width: '180px', height: '35px' }}
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button
            type="submit"
            className="btn btn-warning"
            style={{ marginLeft: '10px', width: '80px', height: '35px' }}
          >
            조회
          </button>
          <Link
            to="/monthly/new"
            className="btn btn-primary"
            style={{ marginLeft: '30px', width: '120px', height: '35px' }}
          >
            할일 등록
          </Link>
        </form>
      </div>
      <hr></hr>
      <div>
        <MonthlyTable
          monthlyList={CurrentMonthList(monthlyList)}
          page={page}
          stateRefresh={stateRefresh}
        />
        <MonthlyPage
          total={monthlyList.length}
          page={page}
          setPage={setPage}
          limit={limit}
        />
        {/* <PaginationBLD
              total={todoList.length}
              page={page}
              setPage={setPage}
              limit={limit} /> */}
      </div>
    </div>
  );
}

export default MonthlyMain;
