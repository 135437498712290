import React from 'react';
import _ from 'lodash';

function VehiclePage(props) {
  const { total, page, limit, siblings } = props;
  const lastPage = Math.ceil(total / limit);

  function returnPaginationRange(lastPage, page, siblings) {
    let totalPageNoInArray = 7 + siblings;
    if (totalPageNoInArray >= lastPage) {
      _.range(1, lastPage + 1);
    }

    let leftSiblingsIndex = Math.max(page - siblings, 1);
    let rightSiblingsIndex = Math.min(page + siblings, lastPage);

    let showLeftDots = leftSiblingsIndex > 2;
    let showRightDots = rightSiblingsIndex < lastPage - 2;

    if (!showLeftDots && showRightDots) {
      let leftItemsCount = 3 + 2 * siblings;
      let leftRange = _.range(1, leftItemsCount + 1);
      return [...leftRange, ' ...', lastPage];
    } else if (showLeftDots & !showRightDots) {
      let rightItemsCount = 3 + 2 * siblings;
      let rightRange = _.range(lastPage - rightItemsCount + 1, lastPage + 1);
      return [1, '... ', ...rightRange];
    } else {
      let middleRange = _.range(leftSiblingsIndex, rightSiblingsIndex + 1);
      return [1, '... ', ...middleRange, ' ...', lastPage];
    }
  }

  let array = returnPaginationRange(lastPage, page, siblings);

  const pageClick = (value) => {
    if (value === '&laquo;' || value === '... ') {
      props.setPage(1);
    } else if (value === '&lsaquo;') {
      if (page !== 1) {
        props.setPage(page - 1);
      }
    } else if (value === '&rsaquo;') {
      if (page !== lastPage) {
        props.setPage(page + 1);
      }
    } else if (value === '&raquo;' || value === ' ...') {
      props.setPage(lastPage);
    } else {
      props.setPage(value);
    }
  };

  return (
    <ul className="pagination pagination-md justify-content-center">
      <li className="page-item">
        <span onClick={() => pageClick('&laquo;')} className="page-link">
          &laquo;
        </span>
      </li>
      <li className="page-item">
        <span onClick={() => pageClick('&lsaquo;')} className="page-link">
          &lsaquo;
        </span>
      </li>
      {array.map((value, valueIndex) => {
        if (value === page) {
          return (
            <li className="page-item active" key={valueIndex}>
              <span onClick={() => pageClick(value)} className="page-link">
                {value}
              </span>
            </li>
          );
        } else {
          return (
            <li className="page-item" key={valueIndex}>
              <span onClick={() => pageClick(value)} className="page-link">
                {value}
              </span>
            </li>
          );
        }
      })}
      <li className="page-item">
        <span onClick={() => pageClick('&rsaquo;')} className="page-link">
          &rsaquo;
        </span>
      </li>
      <li className="page-item">
        <span
          onClick={() => pageClick('&raquo;')}
          className="page-link"
          style={{ cursor: 'pointer' }}
        >
          &raquo;
        </span>
      </li>
    </ul>
  );
}

export default VehiclePage;
