// import { Button, IconButton } from 'react-icons';
// import Button from 'react-bootstrap/Button';
import React, { useRef, useState } from 'react';
import { MdAttachment } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import './FileUploader.css';

function FileUploaderOne(props) {
  // const { accept, onSelectFile, onDeleteFile, disabled } = props;
  const { onSelectFile, onDeleteFile } = props;
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
    onSelectFile(event);
  };

  const onDeleteFileHandler = () => {
    setFile(null);
    hiddenFileInput.current.value = null;
    onDeleteFile();
  };

  return (
    //     border: 1px solid #ced4da;
    //   border-radius: 3px;
    //   display: flex;
    //   justify-content: space-between;
    //   width: 700px;
    <div
      className="d-flex justify-content-between"
      style={{
        // width: '100%',
        height: '35px',
        border: '1px solid #ced4da',
        borderRadius: '3px',
      }}
    >
      {/* <div className={`file-div ${disabled && 'disabled'}`}> */}
      <div className="d-flex w-100">
        {/* <Button
          className="attachment-icon"
          onClick={handleClick}
          disabled={disabled}
        > */}
        <MdAttachment
          style={{ width: '5%', marginTop: '7px', fontSize: '23px' }}
          onClick={handleClick}
          // disabled={disabled}
        />
        <input
          type="file"
          id="actual-btn"
          // accept={accept}
          ref={hiddenFileInput}
          onChange={handleChange}
          hidden
          // disabled={disabled}
          data-testid="file-upload-input"
        />
        {/* <div className="file-name" style={{ width: '200px', marginTop: '5px', marginLeft: '0.5rem' }}> */}
        <div style={{ width: '90%', marginTop: '5px', marginLeft: '0.5rem' }}>
          {file ? <div>{file?.name}</div> : <div>선택된 파일 없음</div>}
        </div>
        {/* </Button> */}
      </div>
      {/* <div className={`${disabled && 'disabled'}`} style={{ width: '30px' }}> */}
      {/* <IconButton
          aria-label="delete"
          disabled={disabled}
          color="primary"
          onClick={onDeleteFileHandler}
        > */}
      <div style={{ width: '5%' }}>
        <MdDelete
          aria-label="delete"
          // disabled={disabled}
          color="primary"
          onClick={onDeleteFileHandler}
        />
        {/* </IconButton> */}
      </div>
    </div>
  );
}

export default FileUploaderOne;
