import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BoardPage from './BoardPage';
import BoardTable from './BoardTable';

function BoardMain() {
  const [boardList, setBoardList] = useState([]); //대상처 배열
  const [searchText, setSearchText] = useState(''); //검색어

  const [page, setPage] = useState(1); //page 1 설정
  const limit = 20; //화면 최대 갯수

  function stateRefresh() {
    const sql = `http://221.143.168.230:3500/board`;
    axios
      .get(sql)
      .then((res) => setBoardList(res.data))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    stateRefresh();
  }, []);

  const CurrentBoardList = (boardList) => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const slicedList = boardList.slice(startIndex, endIndex);
    return slicedList;
  };

  const handleSearch = async (e) => {
    if (searchText.length === 0) {
      alert('검색어를 입력하세요...');
    } else {
      e.preventDefault();
      const url = `http://221.143.168.230:3500/board/search?q=${searchText}`;
      await axios
        .get(url)
        .then((res) => {
          setBoardList(res.data);
          setSearchText('');
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="container">
      <div className="d-flex mt-4">
        <span
          style={{
            width: '50px',
            marginTop: '10px',
            // marginLeft: '40px',
            fontSize: '15px',
            fontWeight: '600',
          }}
        >
          게시판
        </span>
        <form onSubmit={handleSearch} className="d-inline-flex">
          <input
            type="text"
            id="search"
            className="form-control"
            style={{ marginLeft: '800px', width: '180px', height: '35px' }}
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button
            type="submit"
            className="btn btn-warning"
            style={{ marginLeft: '10px', width: '80px', height: '35px' }}
          >
            조회
          </button>
          <Link
            to="/board-new"
            className="btn btn-primary"
            style={{ marginLeft: '30px', width: '100px', height: '35px' }}
          >
            글 작성
          </Link>
        </form>
      </div>
      <hr className="mt-4" />
      <div className="mx-auto mt-3">
        <BoardTable
          boardList={CurrentBoardList(boardList)}
          page={page}
          stateRefresh={stateRefresh}
        />
        <BoardPage
          total={boardList.length}
          page={page}
          setPage={setPage}
          limit={limit}
          siblings={1}
        />
      </div>
    </div>
  );
}

export default BoardMain;
