import axios from 'axios';
// import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import './CreateUser.css';

function MonthlyNew() {
  const navigate = useNavigate();
  const [changed, setChanged] = useState(false);
  const [data, setData] = useState({
    tmonth: '',
    title: '',
    contents: '',
  });

  const handleChange = (e) => {
    setChanged(true);
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (changed) {
      try {
        await axios.post('http://localhost:8081/monthcheck-new', data);
        navigate('/monthly');
      } catch (err) {
        console.log(err);
      }
    } else {
      alert('변경된 사항이 없습니다.');
      navigate('/monthly');
    }
  };

  return (
    <div className="mt-3 ml-5">
      <div className="h5 font-weight-bold">월별 할일 작성</div>
      <form style={{ marginTop: '20px' }}>
        <div className="d-flex mt-3">
          <label htmlFor="tmonth" style={{ width: '90px' }}>
            업무 월 :
          </label>
          <input
            type="number"
            className="form-control"
            min="1"
            max="12"
            style={{ width: '110px' }}
            placeholder="월 입력"
            id="tmonth"
            name="tmonth"
            onChange={handleChange}
          />
        </div>
        <div className="d-flex mt-3">
          <label htmlFor="title" style={{ width: '90px' }}>
            제목 :
          </label>
          <input
            type="text"
            className="form-control"
            style={{ width: '800px' }}
            placeholder="제목을 입력해 주세요"
            id="title"
            name="title"
            onChange={handleChange}
          />
        </div>
        <div className="d-flex mt-3">
          <label htmlFor="contents" style={{ width: '90px' }}>
            내용 :
          </label>
          <textarea
            type="text"
            className="form-control"
            style={{ width: '800px', height: '200px' }}
            placeholder="내용을 입력해 주세요"
            id="contents"
            name="contents"
            onChange={handleChange}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          style={{
            width: '100px',
            marginLeft: '75px',
            marginTop: '20px',
            height: '35px',
          }}
          onClick={handleSave}
        >
          저장
        </button>
        <Link
          to="/monthly"
          className="btn btn-success"
          style={{
            width: '100px',
            marginLeft: '600px',
            marginTop: '20px',
            height: '35px',
          }}
        >
          목록
        </Link>
      </form>
    </div>
  );
}

export default MonthlyNew;
