import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import FileUploaderOne from '../utils/FileUploaderOne';

function BuildingEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [daejangFile, setDaejangFile] = useState('');
  const [contractFile, setContractFile] = useState('');
  const [susingiFile1, setSusingiFile1] = useState('');
  const [susingiFile2, setSusingiFile2] = useState('');
  const [susingiFile3, setSusingiFile3] = useState('');
  const [pumpFile1, setPumpFile1] = useState('');
  const [pumpFile2, setPumpFile2] = useState('');
  const [pumpFile3, setPumpFile3] = useState('');

  const [boolBuilding, setBoolBuilding] = useState(false);
  const [boolContract, setBoolContract] = useState(false);
  const [boolFacility, setBoolFacility] = useState(false);

  const [building, setBuilding] = useState({
    DocuNum: '',
    Service: '',
    Damdang: '',
    BLD_Name: '',
    Zip: '',
    Address1: '',
    Address2: '',
    ADate: '',
    PDate: '',
    PlanDate: '',
    Floor: '',
    Basement: '',
    Dong: '',
    Area1: '',
    Area2: '',
    Area3: '',
    Geub: '',
    Yongdo: '',
    Sede: '',
    Dajung: '',
    BFOS: '',
    Jakdong: '',
    Jonghap: '',
    Manager1: '',
    Phone1: '',
    Email1: '',
    Manager2: '',
    Phone2: '',
    Email2: '',
    SafetyMain: '',
    SafetyType: '',
    SafetySub: '',
    Remark: '',
    Daejang: '',
    isDeleted: '',
  });

  const [contract, setContract] = useState({
    DocuNum: '',
    Owner1: '',
    OwnerPhone1: '',
    OwnerEmail1: '',
    Owner2: '',
    OwnerPhone2: '',
    OwnerEmail2: '',
    Comment1: '',
    Comment2: '',
    Contract: '',
  });

  const [facility, setFacility] = useState({
    DocuNum: '',
    SuJangso: '',
    Susingi1: '',
    Susingi2: '',
    Susingi3: '',
    PuJangso: '',
    Pump1: '',
    Pump2: '',
    Pump3: '',
  });

  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/building/info1/' + id)
      .then((res) => setBuilding(res.data[0]))
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/building/info2/' + id)
      .then((res) => setContract(res.data[0]))
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    axios
      .get('http://221.143.168.230:3500/building/info3/' + id)
      .then((res) => setFacility(res.data[0]))
      .catch((err) => console.log(err));
  }, [id]);

  const handleEditBuilding = (e) => {
    setBoolBuilding(true);
    var result;
    if (e.target.name === 'Service') {
      result = Number(e.target.value);
    } else {
      result = e.target.value;
    }
    setBuilding((prev) => ({ ...prev, [e.target.name]: result }));
  };

  const handleEditContract = (e) => {
    setBoolContract(true);

    var result = e.target.value;
    setContract((prev) => ({ ...prev, [e.target.name]: result }));
  };

  const handleEditFacility = (e) => {
    setBoolFacility(true);

    var result = e.target.value;
    setFacility((prev) => ({ ...prev, [e.target.name]: result }));
  };

  const onSelectDaejangFile = (e) => {
    setDaejangFile(e.target.files[0]);
  };

  const onSelectContractFile = (e) => {
    setContractFile(e.target.files[0]);
  };

  const onSelectSusingiFile1 = (e) => {
    setSusingiFile1(e.target.files[0]);
  };

  const onSelectSusingiFile2 = (e) => {
    setSusingiFile2(e.target.files[0]);
  };

  const onSelectSusingiFile3 = (e) => {
    setSusingiFile3(e.target.files[0]);
  };

  const onSelectPumpFile1 = (e) => {
    setPumpFile1(e.target.files[0]);
  };

  const onSelectPumpFile2 = (e) => {
    setPumpFile2(e.target.files[0]);
  };

  const onSelectPumpFile3 = (e) => {
    setPumpFile3(e.target.files[0]);
  };

  const onDeleteFileHandler = () => {};

  const saveEdit = async () => {
    if (boolBuilding) {
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/building/' + id,
          building
        );
      } catch (err) {
        console.log(err);
      }
    }

    if (daejangFile) {
      const formData = new FormData();
      formData.append('daejang', daejangFile);
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/daejangFile/' + id,
          formData
        );
      } catch (err) {
        console.log(err);
      }
    }

    if (boolContract) {
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/contract/' + id,
          building
        );
      } catch (err) {
        console.log(err);
      }
    }

    if (contractFile) {
      const formData = new FormData();
      formData.append('contract', contractFile);
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/contractFile/' + id,
          formData
        );
      } catch (err) {
        console.log(err);
      }
    }

    if (boolFacility) {
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/facility/' + id,
          facility
        );
      } catch (err) {
        console.log(err);
      }
    }

    if (susingiFile1) {
      const formData = new FormData();
      formData.append('susingifile1', susingiFile1);
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/facility/susingiFile1/' + id,
          formData
        );
      } catch (err) {
        console.log(err);
      }
    }

    if (susingiFile2) {
      const formData = new FormData();
      formData.append('susingifile2', susingiFile2);
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/facility/susingiFile2/' + id,
          formData
        );
      } catch (err) {
        console.log(err);
      }
    }

    if (susingiFile3) {
      const formData = new FormData();
      formData.append('susingifile3', susingiFile3);
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/facility/susingiFile3/' + id,
          formData
        );
      } catch (err) {
        console.log(err);
      }
    }

    if (pumpFile1) {
      const formData = new FormData();
      formData.append('pumpfile1', pumpFile1);
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/facility/pumpFile1/' + id,
          formData
        );
      } catch (err) {
        console.log(err);
      }
    }

    if (pumpFile2) {
      const formData = new FormData();
      formData.append('pumpfile2', pumpFile2);
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/facility/pumpFile2/' + id,
          formData
        );
      } catch (err) {
        console.log(err);
      }
    }

    if (pumpFile3) {
      const formData = new FormData();
      formData.append('pumpfile3', pumpFile3);
      try {
        await axios.put(
          'http://221.143.168.230:3500/building/facility/pumpFile3/' + id,
          formData
        );
      } catch (err) {
        console.log(err);
      }
    }

    navigate('/building');
  };

  return (
    <div className="mx-auto" style={{ width: '85%' }}>
      <div className="mt-5 shadow p-3 bg-white rounded">
        <div>
          <div className="d-flex flex-row">
            <span style={{ fontSize: '20px', fontWeight: 600 }}>
              1. 기본정보
            </span>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td
                    className="table-secondary"
                    style={{ width: '12%', paddingTop: '15px' }}
                  >
                    관리번호
                  </td>
                  <td style={{ width: '23%', paddingTop: '15px' }}>
                    <span>
                      지안-
                      {building.DocuNum.substring(
                        3,
                        building.DocuNum.length
                      ).padStart(5, '0')}
                    </span>
                  </td>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    업무구분
                  </td>
                  <td style={{ width: '23%' }}>
                    <div className="d-flex">
                      <input
                        type="radio"
                        value="1"
                        name="Service"
                        checked={building.Service === 1}
                        onChange={handleEditBuilding}
                      />
                      <span>월관리</span>
                      <input
                        type="radio"
                        style={{ marginLeft: '50px' }}
                        value="2"
                        name="Service"
                        checked={building.Service === 2}
                        onChange={handleEditBuilding}
                      />
                      <span>자체점검</span>
                    </div>
                  </td>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    업무담당자
                  </td>
                  <td style={{ width: '18%' }}>
                    <input
                      type="text"
                      id="damdang"
                      name="Damdang"
                      defaultValue={building.Damdang}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">건물명</td>
                  <td>
                    <input
                      type="text"
                      id="bldname"
                      name="BLD_Name"
                      value={building.BLD_Name}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                  <td className="table-secondary">주소</td>
                  <td colSpan="3">
                    <div className="d-flex">
                      <input
                        type="text"
                        id="zipcode"
                        name="Zip"
                        value={building.Zip}
                        className="form-control"
                        style={{ width: '90px' }}
                        placeholder="우편번호"
                        onChange={handleEditBuilding}
                      />

                      <input
                        type="text"
                        id="address1"
                        name="Address1"
                        value={building.Address1}
                        className="form-control"
                        style={{ width: '300px', marginLeft: '10px' }}
                        onChange={handleEditBuilding}
                      />
                      <input
                        type="text"
                        id="address2"
                        name="Address2"
                        value={building.Address2}
                        className="form-control"
                        style={{ width: '130px', marginLeft: '10px' }}
                        placeholder="상세주소"
                        onChange={handleEditBuilding}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">사용승인일</td>
                  <td>
                    <input
                      type="date"
                      id="adate"
                      name="ADate"
                      defaultValue={building.ADate}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                  <td className="table-secondary">건축허가일</td>
                  <td>
                    <input
                      type="date"
                      id="pdate"
                      name="PDate"
                      defaultValue={building.PDate}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                  <td className="table-secondary">소방계획서</td>
                  <td>
                    <input
                      type="date"
                      id="plandate"
                      name="PlanDate"
                      defaultValue={building.PlanDate}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">층수/동수</td>
                  <td>
                    <div className="d-flex">
                      <span style={{ fontSize: '13px' }}>지상{'\u00A0'}</span>
                      <input
                        type="text"
                        id="floor"
                        name="Floor"
                        value={building.Floor}
                        className="form-control"
                        style={{ width: '45px' }}
                        onChange={handleEditBuilding}
                      />
                      <span style={{ fontSize: '13px' }}>
                        층, 지하{'\u00A0'}
                      </span>
                      <input
                        type="text"
                        id="basement"
                        name="Basement"
                        value={building.Basement}
                        className="form-control"
                        style={{ width: '45px' }}
                        onChange={handleEditBuilding}
                      />
                      <span style={{ fontSize: '13px' }}>층,{'\u00A0'}</span>
                      <input
                        type="text"
                        id="dong"
                        name="Dong"
                        value={building.Dong}
                        className="form-control"
                        style={{ width: '45px' }}
                        onChange={handleEditBuilding}
                      />
                      <span style={{ fontSize: '13px' }}>개동</span>
                    </div>
                  </td>
                  <td className="table-secondary">연면적</td>
                  <td colSpan="3">
                    <div className="d-flex">
                      <input
                        type="text"
                        id="area1"
                        name="Area1"
                        value={building.Area1}
                        className="form-control"
                        style={{ width: '130px', marginLeft: '30px' }}
                        onChange={handleEditBuilding}
                      />
                      <span>{'\u00A0'}m²</span>
                      {building.Dong > 1 ? (
                        <div className="d-flex">
                          <input
                            type="text"
                            id="area2"
                            name="Area2"
                            value={building.Area2}
                            className="form-control"
                            style={{ width: '130px', marginLeft: '30px' }}
                            onChange={handleEditBuilding}
                          />
                          <span>{'\u00A0'}m²</span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {building.Dong > 2 ? (
                        <div className="d-flex">
                          <input
                            type="text"
                            id="area3"
                            name="Area3"
                            value={building.Area3}
                            className="form-control"
                            style={{ width: '130px', marginLeft: '30px' }}
                            onChange={handleEditBuilding}
                          />
                          <span>{'\u00A0'}m²</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">용도</td>
                  <td colSpan="3">
                    <input
                      type="text"
                      id="yongdo"
                      name="Yongdo"
                      value={building.Yongdo}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                  <td className="table-secondary">세대</td>
                  <td>
                    <input
                      type="text"
                      id="sede"
                      name="Sede"
                      value={building.Sede}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">다중이용업소</td>
                  <td colSpan="3">
                    <input
                      type="text"
                      id="dajung"
                      name="Dajung"
                      value={building.Dajung}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                  <td className="table-secondary">건물관리업체</td>
                  <td>
                    <input
                      type="text"
                      id="bfos"
                      name="BFOS"
                      value={building.BFOS}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">작동점검</td>
                  <td>
                    <select
                      id="jakdong"
                      name="Jakdong"
                      value={building.Jakdong}
                      className="form-select"
                      onChange={handleEditBuilding}
                    >
                      <option value="0">선택</option>
                      <option value="1">1월</option>
                      <option value="2">2월</option>
                      <option value="3">3월</option>
                      <option value="4">4월</option>
                      <option value="5">5월</option>
                      <option value="6">6월</option>
                      <option value="7">7월</option>
                      <option value="8">8월</option>
                      <option value="9">9월</option>
                      <option value="10">10월</option>
                      <option value="11">11월</option>
                      <option value="12">12월</option>
                    </select>
                  </td>
                  <td className="table-secondary">종합점검</td>
                  <td>
                    <select
                      id="jonghap"
                      name="Jonghap"
                      value={building.Jonghap}
                      className="form-select"
                      onChange={handleEditBuilding}
                    >
                      <option value="0">선택</option>
                      <option value="1">1월</option>
                      <option value="2">2월</option>
                      <option value="3">3월</option>
                      <option value="4">4월</option>
                      <option value="5">5월</option>
                      <option value="6">6월</option>
                      <option value="7">7월</option>
                      <option value="8">8월</option>
                      <option value="9">9월</option>
                      <option value="10">10월</option>
                      <option value="11">11월</option>
                      <option value="12">12월</option>
                    </select>
                  </td>
                  <td className="table-secondary">건물등급</td>
                  <td>
                    <select
                      id="geub"
                      name="Geub"
                      value={building.Geub}
                      className="form-select"
                      onChange={handleEditBuilding}
                    >
                      <option value="선택">선택</option>
                      <option value="특급">특급</option>
                      <option value="1급">1급</option>
                      <option value="2급">2급</option>
                      <option value="3급">3급</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">관리인 #1</td>
                  <td>
                    <input
                      type="text"
                      id="manager1"
                      name="Manager1"
                      value={building.Manager1}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                  <td className="table-secondary">연락처 #1</td>
                  <td>
                    <input
                      type="text"
                      id="phone1"
                      name="Phone1"
                      value={building.Phone1}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                  <td className="table-secondary">이메일 #1</td>
                  <td>
                    <input
                      type="text"
                      id="email1"
                      name="Email1"
                      value={building.Email1}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">관리인 #2</td>
                  <td>
                    <input
                      type="text"
                      id="manager2"
                      name="Manager2"
                      value={building.Manager2}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                  <td className="table-secondary">연락처 #2</td>
                  <td>
                    <input
                      type="text"
                      id="phone2"
                      name="Phone2"
                      value={building.Phone2}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                  <td className="table-secondary">이메일 #2</td>
                  <td>
                    <input
                      type="text"
                      id="email2"
                      name="Email2"
                      value={building.Email2}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">주 소방안전 관리자</td>
                  <td colSpan="3">
                    <input
                      type="text"
                      id="safetymain"
                      name="SafetyMain"
                      value={building.SafetyMain}
                      className="form-control"
                      placeholder="이름, 연락처, 선임일자 ..."
                      onChange={handleEditBuilding}
                    />
                  </td>
                  <td className="table-secondary">구분</td>
                  <td>
                    <input
                      type="text"
                      id="safetytype"
                      name="SafetyType"
                      value={building.SafetyType}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">보조 소방안전 관리자</td>
                  <td colSpan="5">
                    <input
                      type="text"
                      id="safetysub"
                      name="SafetySub"
                      value={building.SafetySub}
                      className="form-control"
                      onChange={handleEditBuilding}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">특기 사항</td>
                  <td colSpan="5">
                    <textarea
                      type="text"
                      id="remark"
                      name="Remark"
                      value={building.Remark}
                      className="form-control"
                      style={{ width: '100%', height: '100px' }}
                      onChange={handleEditBuilding}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">건축물대장</td>
                  <td>
                    {building.Daejang ? (
                      <span>
                        {building.Daejang.substring(
                          14,
                          building.Daejang.length
                        )}
                      </span>
                    ) : (
                      <>없음</>
                    )}
                  </td>
                  <td colSpan="4">
                    <FileUploaderOne
                      onSelectFile={onSelectDaejangFile}
                      onDeleteFile={onDeleteFileHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-5 shadow p-3 bg-white rounded">
        <div className="d-flex flex-row">
          <span style={{ fontSize: '20px', fontWeight: 600 }}>
            2. 계약자 정보
          </span>
        </div>
        <div className="mx-3 mt-3">
          <table className="table table-bordered table-light">
            <tbody>
              <tr>
                <td className="table-secondary" style={{ width: '12%' }}>
                  계약자 #1
                </td>
                <td style={{ width: '23%' }}>
                  <input
                    type="text"
                    id="owner1"
                    name="Owner1"
                    value={contract.Owner1}
                    className="form-control"
                    onChange={handleEditContract}
                  />
                </td>
                <td className="table-secondary" style={{ width: '12%' }}>
                  연락처 #1
                </td>
                <td style={{ width: '23%' }}>
                  <input
                    type="text"
                    id="ownerphone1"
                    name="OwnerPhone1"
                    value={contract.OwnerPhone1}
                    className="form-control"
                    onChange={handleEditContract}
                  />
                </td>
                <td className="table-secondary" style={{ width: '12%' }}>
                  이메일 #1
                </td>
                <td style={{ width: '18%' }}>
                  <input
                    type="text"
                    id="owneremail1"
                    name="OwnerEmail1"
                    value={contract.OwnerEmail1}
                    className="form-control"
                    onChange={handleEditContract}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-secondary">계약자 #2</td>
                <td>
                  <input
                    type="text"
                    id="owner2"
                    name="Owner2"
                    value={contract.Owner2}
                    className="form-control"
                    onChange={handleEditContract}
                  />
                </td>
                <td className="table-secondary">연락처 #2</td>
                <td>
                  <input
                    type="text"
                    id="ownerphone2"
                    name="OwnerPhone2"
                    value={contract.OwnerPhone2}
                    className="form-control"
                    onChange={handleEditContract}
                  />
                </td>
                <td className="table-secondary">이메일 #2</td>
                <td>
                  <input
                    type="text"
                    id="owneremail2"
                    name="OwnerEmail2"
                    value={contract.OwnerEmail2}
                    className="form-control"
                    onChange={handleEditContract}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-secondary">특기 사항 #1</td>
                <td colSpan="5">
                  <textarea
                    type="text"
                    id="comment1"
                    name="Comment1"
                    value={contract.Comment1}
                    className="form-control"
                    style={{ width: '100%', height: '100px' }}
                    placeholder="특기 사항"
                    onChange={handleEditContract}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-secondary">특기 사항 #2</td>
                <td colSpan="5">
                  <textarea
                    type="text"
                    id="comment2"
                    name="Comment2"
                    value={contract.Comment2}
                    className="form-control"
                    style={{ width: '100%', height: '100px' }}
                    placeholder="특기 사항"
                    onChange={handleEditContract}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-secondary">계약서</td>
                <td>
                  {contract.Contract ? (
                    <span>
                      {contract.Contract.substring(
                        14,
                        contract.Contract.length
                      )}
                    </span>
                  ) : (
                    <>없음</>
                  )}
                </td>
                <td colSpan="4">
                  <FileUploaderOne
                    onSelectFile={onSelectContractFile}
                    onDeleteFile={onDeleteFileHandler}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-5 shadow p-3 bg-white rounded">
          <div style={{ fontSize: '20px', fontWeight: 600 }}>3. 건물 설비</div>
          <div className="mt-3">
            <span style={{ fontSize: '17px', fontWeight: 600 }}>
              3-1. 수신기
            </span>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    수신기 위치
                  </td>
                  <td colSpan="2">
                    <textarea
                      type="text"
                      id="sujangso"
                      name="SuJangso"
                      value={facility.SuJangso}
                      className="form-control"
                      style={{ width: '100%', height: '100px' }}
                      onChange={handleEditFacility}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">수신기 #1</td>
                  <td style={{ width: '28%' }}>
                    {facility.Susingi1 ? (
                      <span>
                        {facility.Susingi1.substring(
                          14,
                          facility.Susingi1.length
                        )}
                      </span>
                    ) : (
                      <>없음</>
                    )}
                  </td>
                  <td style={{ width: '60%' }}>
                    <FileUploaderOne
                      onSelectFile={onSelectSusingiFile1}
                      onDeleteFile={onDeleteFileHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">수신기 #2</td>
                  <td>
                    {facility.Susingi2 ? (
                      <span>
                        {facility.Susingi2.substring(
                          14,
                          facility.Susingi2.length
                        )}
                      </span>
                    ) : (
                      <>없음</>
                    )}
                  </td>
                  <td>
                    <FileUploaderOne
                      onSelectFile={onSelectSusingiFile2}
                      onDeleteFile={onDeleteFileHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">수신기 #3</td>
                  <td>
                    {facility.Susingi3 ? (
                      <span>
                        {facility.Susingi3.substring(
                          14,
                          facility.Susingi3.length
                        )}
                      </span>
                    ) : (
                      <>없음</>
                    )}
                  </td>
                  <td>
                    <FileUploaderOne
                      onSelectFile={onSelectSusingiFile3}
                      onDeleteFile={onDeleteFileHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td style={{ width: '33%' }}>
                    {facility.Susingi1 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Susingi1}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                  <td style={{ width: '33%' }}>
                    {facility.Susingi2 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Susingi2}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                  <td style={{ width: '34%' }}>
                    {facility.Susingi3 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Susingi3}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-3">
            <span style={{ fontSize: '17px', fontWeight: 600 }}>3-2. 펌프</span>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td className="table-secondary" style={{ width: '12%' }}>
                    펌프 위치
                  </td>
                  <td colSpan="2">
                    <textarea
                      type="text"
                      id="pujangso"
                      name="PuJangso"
                      value={facility.PuJangso}
                      className="form-control"
                      style={{ width: '100%', height: '100px' }}
                      onChange={handleEditFacility}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">펌프 #1</td>
                  <td style={{ width: '28%' }}>
                    {facility.Pump1 ? (
                      <span>
                        {facility.Pump1.substring(14, facility.Pump1.length)}
                      </span>
                    ) : (
                      <>없음</>
                    )}
                  </td>
                  <td style={{ width: '60%' }}>
                    <FileUploaderOne
                      onSelectFile={onSelectPumpFile1}
                      onDeleteFile={onDeleteFileHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">펌프 #2</td>
                  <td>
                    {facility.Pump2 ? (
                      <span>
                        {facility.Pump2.substring(14, facility.Pump2.length)}
                      </span>
                    ) : (
                      <>없음</>
                    )}
                  </td>
                  <td>
                    <FileUploaderOne
                      onSelectFile={onSelectPumpFile2}
                      onDeleteFile={onDeleteFileHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="table-secondary">펌프 #3</td>
                  <td>
                    {facility.Pump3 ? (
                      <span>
                        {facility.Pump3.substring(14, facility.Pump3.length)}
                      </span>
                    ) : (
                      <>없음</>
                    )}
                  </td>
                  <td>
                    <FileUploaderOne
                      onSelectFile={onSelectPumpFile3}
                      onDeleteFile={onDeleteFileHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mx-3 mt-3">
            <table className="table table-bordered table-light">
              <tbody>
                <tr>
                  <td style={{ width: '33%' }}>
                    {facility.Pump1 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Pump1}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                  <td style={{ width: '33%' }}>
                    {facility.Pump2 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Pump2}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                  <td style={{ width: '34%' }}>
                    {facility.Pump3 ? (
                      <img
                        src={`http://221.143.168.230:3500/images/${facility.Pump3}`}
                        width="100%"
                        height="300"
                        alt=""
                      />
                    ) : (
                      <>
                        <img src="" width="100%" height="300" alt="" />
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="d-flex flex-row justify-content-end"
            style={{ marginRight: '30px' }}
          >
            <button className="btn btn-primary" onClick={saveEdit}>
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuildingEdit;
