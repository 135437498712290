import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function VehicleTable(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);

  const handleClose = () => {
    setShow(false);
  };

  function handleShow(info) {
    setShow(true);
    handleVehicle(info);
  }

  const handleVehicle = async (info) => {
    axios
      .get('http://221.143.168.230:3500/vehicle/' + info)
      .then((res) => setList(res.data[0]))
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setList((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `http://221.143.168.230:3500/board-update/${e.currentTarget.id}`,
        list
      );
      handleClose();
      props.stateRefresh();
      navigate('/vehicle');
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (window.confirm('해당 글을 삭제하겠습니까?')) {
      try {
        await axios.delete(
          `http://221.143.168.230:3500/vehicle/${e.currentTarget.id}`
        );
        handleClose();
        props.stateRefresh();
        navigate('/vehicle');
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="container">
      <table className="table mx-auto">
        <thead className="thead-light">
          <tr>
            <th style={{ width: '100px', textAlign: 'center' }}>차량</th>
            <th style={{ width: '250px', textAlign: 'center' }}>모델</th>
            <th style={{ width: '150px', textAlign: 'center' }}>보험사</th>
            <th style={{ width: '150px', textAlign: 'center' }}>사용자</th>
            <th style={{ width: '250px', textAlign: 'center' }}>보험기간</th>
          </tr>
        </thead>
        <tbody>
          {props.vehicleList.map((v, index) => (
            <tr key={index}>
              {/* <td style={{width:'100px', textAlign:'center'}}>{(props.page-1)*10 + index + 1}</td> */}
              <td
                style={{
                  textAlign: 'center',
                  fontSize: '17px',
                  fontWeight: '600',
                  color: 'blue',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleShow(v.ID);
                }}
              >
                {v.Vehicle}
              </td>
              <td style={{ textAlign: 'left' }}>
                {v.Model.length > 25
                  ? v.Model.substr(0, 25) + '  ...'
                  : v.Model}
              </td>
              <td style={{ textAlign: 'center' }}>{v.Company}</td>
              <td style={{ textAlign: 'center' }}>{v.User}</td>
              <td style={{ textAlign: 'center' }}>
                {moment(v.Start).format('YYYY-MM-DD') +
                  ' ~ ' +
                  moment(v.End).format('YYYY-MM-DD')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        size="lg" //"sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>차량 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>차량번호</td>
                  <td>{list.Vehicle}</td>
                </tr>
                <tr>
                  <td>차량모델</td>
                  <td>{list.Model}</td>
                </tr>
                <tr>
                  <td>보험회사</td>
                  <td>{list.Company}</td>
                </tr>
                <tr>
                  <td>사용자</td>
                  <td>{list.User}</td>
                </tr>
                <tr>
                  <td>보험시작일</td>
                  <td>{list.Start}</td>
                </tr>
                <tr>
                  <td>보험만료일</td>
                  <td>{list.End}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>
                    <Button
                      // style={{ marginLeft: '20px' }}
                      variant="secondary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default VehicleTable;
