import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';
import DashBoard from './components/dashboard/DashBoard';
import VehicleMain from './components/vehicle/VehicleMain';
import VehicleNew from './components/vehicle/VehicleNew';
import EmployeeMain from './components/employee/EmployeeMain';
import EmployeeNew from './components/employee/EmployeeNew';
import EmployeeUpdate from './components/employee/EmployeeUpdate';
import Dayoff from './components/vacation/Dayoff';
import BoardMain from './components/board/BoardMain';
import BoardNew from './components/board/BoardNew';
import BuildingMain from './components/building/BuildingMain';
import BuildingRegister from './components/building/BuildingRegister';
import BuildingInfo from './components/building/BuildingInfo';
import BuildingEdit from './components/building/BuildingEdit';
import MonthlyMain from './components/monthlycheck/MonthlyMain';
import MonthlyNew from './components/monthlycheck/MonthlyNew';
import Schedule from './components/building/Schedule';
import Holidays from './components/building/Holidays';
import Report from './components/building/Report';
import Home from './components/Users/Home';
import Login from './components/Users/Login';
import Navbarmenu from './components/Users/Navbarmenu';
import { AuthProvider } from './components/Users/AuthProvider';
import RequireAuth from './components/Users/RequireAuth';

const ROLES = {
  User: 1964,
  Editor: 1996,
  Admin: 1993,
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <nav>
            <Navbarmenu />
          </nav>
          <Routes>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/dashboard" element={<DashBoard />} />
              {/* <Route path="/dashboard2" element={<BoardDashboard2 />} /> */}
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/" element={<Home />} />
            </Route>
            {/* <Route path="/home" element={<Home />} /> */}
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/building" element={<BuildingMain />} />
              <Route path="/bldregister" element={<BuildingRegister />} />
              <Route path="/building/info/:id" element={<BuildingInfo />} />
              <Route path="/building/edit/:id" element={<BuildingEdit />} />
              <Route path="/bldtestplan" element={<Schedule />} />
              <Route path="/bldreport" element={<Report />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/board" element={<BoardMain />} />
              <Route path="/board-new" element={<BoardNew />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
              <Route path="/employee" element={<EmployeeMain />} />
              <Route path="/employee/new" element={<EmployeeNew />} />
              <Route path="/employee/update/:id" element={<EmployeeUpdate />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
              <Route path="/vehicle" element={<VehicleMain />} />
              <Route path="/vehicle/new" element={<VehicleNew />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/monthly" element={<MonthlyMain />} />
              <Route path="/monthly/new" element={<MonthlyNew />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/holidays" element={<Holidays />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/vacation" element={<Dayoff />} />
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
