import React from 'react';
// import './PaginationBLD.css';

function BuildingPage(props) {
  const { total, page, limit } = props;
  const lastPage = Math.ceil(total / limit);

  let array = [];
  for (var i = 1; i <= lastPage; i++) {
    array.push(i);
  }

  const pageClick = (value) => {
    props.setPage(value);
  };

  return (
    <div>
      <hr className="border-gray-400 mt-5" />
      <ul className="pagination pagination-md justify-content-center">
        {array.map((value, valueIndex) => {
          if (value === page) {
            return (
              <li className="page-item active" key={valueIndex}>
                <span onClick={() => pageClick(value)} className="page-link">
                  {value}
                </span>
              </li>
            );
          } else {
            return (
              <li className="page-item" key={valueIndex}>
                <span
                  onClick={() => pageClick(value)}
                  className="page-link"
                  style={{ cursor: 'pointer' }}
                >
                  {value}
                </span>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}

export default BuildingPage;
